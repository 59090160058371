const kr={
    
    
    confirm: "확인",
    coming_soon: "머지않아",
    processing: "사무가 제출되었습니다. 기다려 주십시오.",
    error: "오류",
    success: "성공",
    close: "닫기",
    language: "언어",
    language_changed:"language changed",
    install_metamask: "metamask 설치",
    please_connect: "지갑 연결하세요",
    loading: "처리...",
    approve: "승인",
    rejected: "거부",
    running: "달리기",

    // 제목
    package: "가방",
    arbitrage: "배당금",
    transaction: "거래",
    connect: "연결",
    connected: "접속됨",
    connect_wallet: "지갑 연결",

    // 홈 페이지
    // 홈 페이지
    take_of_control: "안전하고 편리함",
    your_finance_with: "부자 증가",
    wall_street_wolves: "월가의 늑대",
    a_secure: "안전한 글로벌 자동화, 유럽연합이 승인한 디지털 금융 서비스 플랫폼",
    generate_wallet: "예언 로봇 WSW",
    get_started: "사용 시작",
    earn_upto: "400% 를 벌면",
    interest: "돌아와!",
    dont_just_let_your: "USDT와 WOLF 암호화폐를 방치하지 마십시오.",
    create_account: "서비스 시작",
    your_key: "당신의 열쇠",
    crypto_market: "암호화 시장",
    unlock_eme: "WSW의 시장 선두, 라이센스 및 관리 거래소에서 안전하게 거래하여 신흥 기회를 신속하고 안정적으로 잠금 해제합니다.",
    exp_arena: "암호화 자산 탐색 경기장",
    check_our_blog: "블록체인, DEFI 및 암호화된 자산의 경쟁 게임과 관련된 주제를 알아보십시오. 저희 안내서를 읽고 디지털 자산 경쟁의 여정을 시작하십시오. (경쟁적인 게임을 시작하고) 게임 시스템에 접근할 수 있는 링크가 있습니다.",
    t1: "4주 전",
    tt1: "약세장이나 강세장, 당황하지 마세요. wsw는 당신에게 다른 수입 선택을 가져다 줍니다.",
    ttt1: "약세장에서 왔어요",
    t2: "2개월 전",
    tt2:"분산자치",
    ttt2: "조직(DAO) 해석",
    t3: "3개월 전",
    tt3: "2022년 최고 수준의 DeFi 암호화폐",
    t4:"3个月前",
    tt4: "2022년 최고의 암호화 트렌드: 게임 및",
    ttt4: "원우주",
    rapid_real: "빠르고 실시간",
    execution: "실행",
    in_the_volatile: "변하기 쉬운 암호화폐 거래에서는 매 초가 중요합니다. 우리의 높은 응답 기술은 대량의 거래를 동시에 실행하는 동시에 즉시 반응할 수 있습니다.",
    maximize: "最大化",
    your_passive_profits: "귀하의 수동적 이윤",
    stacke_your_fund: "우리의 탈중심화 수익률 갑골문 로봇 배당 계획을 통해 당신의 자금을 투자하고 우리의 자동 배당 거래 시스템과 거래하여 업계 어느 곳에서도 비교할 수 없는 연간 수익률을 누릴 수 있습니다.",
    putting_your: "투자 원칙",
    security_first: "투자 원금과 수익 기금의 안전성이 첫 번째 요인",
    Full_eu: "완전한 EU 라이센스 및 관리, ArbiSmart는 계정의 무결성을 보장하고 모든 자동 거래의 프라이버시와 안전을 보호하기 위해 가장 엄격한 기술 협의를 실시합니다.",
    start_now: "지금 시작",

    //외화를 묶다
    what_is_arbitrage: "이익은 무엇입니까?",
    arbitrage_involves: "套利는 한 시장에서 자산을 구매한 다음에 다른 시장에서 자산을 매각하여 둘 사이의 가격차에서 이익을 얻는 것과 관련된다. 套利는 건강한 수익을 제공하는 경향이 있기 때문에 투자자들이 최소한의 위험에 직면하게 하기 때문에 매력적인 투자 기회를 제공한다고 광범위하게 여겨진다.",
    what_is_carbitrage: "암호화 차익이란 무엇입니까?",
    take_adv: "암호화 배당금은 일시적인 가격 저효과 - 동전은 서로 다른 가격으로 동시에 사용할 수 있는 짧은 간격을 이용합니다. 동전은 가격이 가장 낮은 거래소에서 구매한 다음 가격이 가장 높은 거래소에서 판매해서 이윤을 창출합니다.",
    take_adv1: "WSW는 유럽연합이 완전히 감독하는 자동화 시스템으로 한 번에 35개의 거래소를 24/7 스캔합니다.",
    take_adv2: "안전하고 신뢰할 수 있으며 Dell의 앞선 독점 기술로 안심하고 투자할 수 있습니다.",
    take_adv3: "이 플랫폼은 매년 45% 의 투자 규모에 따라 업계의 높은 수익을 창출할 수 있습니다.",
    why_Be_an_arbitrage: "왜 배당금이 되었는가",
    investor_with:"investor with",
    s1:"비길 데 없는 이윤 잠재력",
    ss1: "당신의 법정 화폐와 암호화폐 자본을 이용하여 비길 데 없는 피동적인 이윤과 업계 선두 이율을 얻을 수 있는 기회를 누리십시오.",
    s2:"가장 선진적인 기술",
    ss2: "대량의 데이터를 번개 속도로 처리할 수 있는 선진 기술을 이용하여 암호화폐 시장에서 실시간으로 이익을 얻는다.",
    s3:"유럽연합의 허가와 감독",
    ss3: "안심히 투자하고 특별히 안전하고 허가된 플랫폼을 가지고 가장 엄격한 감독 기준에 부합",
    s4: "모든 장치를 통한 액세스",
    ss4: "집에 있거나 외출할 때 포트폴리오를 관리하고 귀하의 컴퓨터, 태블릿PC 또는 핸드폰을 통해 언제든지 귀하의 ArbiSmart 계정에 접근할 수 있습니다.",
    s5:"전문가 24/7 협조",
    ss5: "경험이 풍부한 글로벌 암호화 시장 전문 팀에서 연중무휴 24시간 최고의 서비스 및 지원을 제공합니다.",
    s6: "법정 통화 및 암호화폐 지원",
    ss6: "다양한 법정 화폐와 암호화폐를 이용하여 도모할 수 있는 암호화 거래, 배당금과 지갑 기회를 이용한다",

    // 지원
    contact_us: "연락처",
    get_in_touch_1: "업계 전문가 팀과 연락하여 개인, 전문적인 서비스를 받습니다.",
    get_in_touch_1: "이메일과 실시간 채팅을 통해 전 세계 지원 담당자가 매일 24시간 안내를 제공합니다.",
    open_chat: "채팅 열기",
    email: "e-메일",
    via_email: "언제든지 이메일로 팀 연락support@wallstreetwolves.com",
    live_chat: "월스트리트 늑대 전문가, 글로벌 전문가, 전천후, 실시간 채팅을 통해",

    //소포
    status: "상태",
    arbitrage_amount: "배당 금액",
    arbi_colelcted: "arbi 컬렉션",
    collect_roi: "roi 수집",
    end: "끝",
    your_wallet: "당신의 지갑",
    min: "최소",
    max: "최대값",
    apy:"apy",
    purchase_now: "구매",
    node_purchase: "패키지 구매",
    usdt_amount: "usdt 금액",
    usd_balance: "USDT 잔액",
    wolf_balance: "늑대 균형",
    collect: "수집",
    approve_wolf_notice: "관리비는 wolf의 수량이 필요합니다. 동의하십니까?",
    approve_usdt_notice: "월가의 늑대는 지갑에서 0달러를 공제합니다. 동의하십니까?",
    submit: "제출",
    enter_usdt_amount: "usdt 금액을 입력하십시오.",
    next: "다음",
    confirm_purchase: "구매 확인?",
    purchase_complete: "구매 성공",
    purchase_fail: "구매 실패",
    view_transaction: "자원 관리자에서 트랜잭션 보기",
    pacakge_1_limit: "패키지-A USDT는 100-499 사이여야 합니다.",
    pacakge_2_limit: "B팩 USDT는 500과 1999 사이여야 합니다.",
    pacakge_3_limit: "패키지 C USDT는 2000과 9999 사이여야 합니다.",

    // 쪽지
    note_1: "본 사이트는 월스트리트 늑대팀이 운영하고 있으며, 이 회사는 아시아에 등록되어 있으며, FCIS의 인증을 받아 가상 화폐 서비스를 제공합니다.",
    arbitrage: "배당금",
    accounts: "계좌",
    partnership: "파트너",
    support: "지원",
    company: "회사",
    careers: "직업",
    how_it_work: "작업 방법",
    roadmap: "로드맵",
    rsp: "리스크 정책",
    rp:"환불 정책",
    kyc:"kyc 전략",
    am:"돈세탁 반대 정책",
    tc: "조항과 조건",
    pp:"프라이버시 정책",
    quthorizations: "권한 부여",
    blog: "블로그",
    FAQ: "FAQ",

    //Util
    eth_main_net: "eth 메인넷",
    bsc_main_net: "bsc 메인넷",
    bsc_test_net: "bsc 테스트 네트워크",
    unknown_net:"알 수 없는 네트워크",
    ongoing: "진행 중",
    expired: "만료",
    collected: "수집",

    // 회사
    line_1:"월스트리트 늑대는 국제금융기금회사의 유명한 파트너인 피터, 존, 줄리엔 제시와 앤서니가 공동으로 이끄는 차세대 디지털 기금이다. 새로운 디지털 제국이 곧 일어날 것이다.",
    line_2:"월스트리트 늑대의 목적은 전 세계 전속성 디지털 펀드를 만드는 것이다. 금융 서비스 플랫폼으로서 전 세계 디지털 화폐 애호가들에게 서비스를 제공하고 그들에게 가장 전문적이고 질 높은 디지털 금융 서비스를 제공하는 것이다. 월스트리트 늑대 디지털 펀드는 우선 100% 탈중심화 거래 예측 거래 로봇 이윤 서비스 시스템을 출시하고 생태 화폐를 발행할 것이다전체 디지털 금융 생태계의 개발에 사용된다.",
    line_3:"앞으로 월스트리트 늑대는 디지털 금융을 둘러싸고 많은 업무를 파생시킬 것이다. 디지털 자산 투자 기금, 디지털 자산 대출, 디지털 자산 헤지펀드, 빅데이터 기금, 디지털 자산 관광 부동산 원 우주 기금과 많은 다른 업무를 포함한다. 월스트리트 늑대는 가장 강력한 기술팀, 최고의 기금 합작 파트너와 방대한 글로벌 관계 네트워크를 가지고 있다.ips。",
    line_4: "합리적인 금융 서비스 업무의 폐쇄를 통해 높은 투자 수익률은 반드시 세계의 모든 사용자에게 이익을 가져다 줄 것이다. 부의 증가를 실현할 것이다. 월스트리트 늑대는 당신을 더욱 아름다운 금융 세계로 인도할 것이다.",

    // FAQ
    q1: "q1. 월스트리트 늑대는 어떤 플랫폼입니까?",
    a1: "a1: 월스트리트 늑대는 전 세계와 국제 차세대 디지털 금융 서비스 플랫폼이다. 디지털 금융을 둘러싸고 디지털 자산 투자 펀드, 디지털 자산 대출, 디지털 자산 헤지펀드, 빅데이터 펀드 탱크와 디지털 자산 관광 부동산을 포함한 많은 업무를 파생시킬 것이다. Metaverse 펀드 탱크와 많은 다른 업무를 포함한다.",
    q2: "q2. 월스트리트 늑대는 어떻게 참여했습니까?",
    a2: "a2: Binance 스마트 체인 아래의 빙을 통해 중심화 거래소에 가서 월스트리트 늑대 플랫폼 토큰인 WOLF와 USDT를 구매하고 월스트리트 늑대 플랫폼에 들어가 당신에게 적합한 서비스 패키지를 선택하면 예측 로봇 배당 서비스를 시작할 수 있습니다.",
    q2: "세 번째. 월스트리트 늑대는 어떻게 이익을 얻나요?",
    a3: "a3: 월스트리트 늑대는 로봇 덤핑 서비스가 사용자가 선택한 서비스 패키지에 따라 덤핑을 할 것이라고 예측했다. 매일 덤핑 수입은 사용자가 중심화된 지갑에 있는 협의에 따라 실시간으로 결제되고 일일 수익률은 1.2%에서 4%로 떨어진다.",
    q4: "q4. 월스트리트 늑대는 안전합니까?",
    a4: "a4: 월스트리트 늑대는 유럽과 미국 금융 기구가 비준하고 운영하는 글로벌 디지털 금융 서비스 플랫폼입니다. 많은 유명 펀드 투자 파트너가 공동으로 창설합니다. 월스트리트 늑대는 가장 강력한 기술팀, 가장 좋은 펀드 파트너와 대량의 글로벌 펀드를 가지고 있습니다. 네트워크 보안이 믿을 만합니다.",
    
    //위험 정책
    risk_policy_management: "위험 관리 정책",
    policy: "정책",
    policy_desc: "우리는 위험 관리를 실현하는 최선의 실천을 통해 잠재적인 기회와 불리한 영향을 체계적이고 전면적으로 효과적으로 관리하는 데 주력합니다.",
    philosophy:'철학',
    philosophy_desc: "WSW는 지능적인 위험 부담을 안고 위험이 적극적이고 부정적인 결과를 초래할 수 있음을 인식한다.",
    objectives: "목표",
    objectives_desc: "리스크 관리는 목표를 실현하고 효과적으로 운영하며 인원과 자산을 보호하고 현명한 결정을 내리며 적용되는 법률과 법규를 준수하는 데 도움을 줍니다.",
    business_plan: "업무 계획",
    business_plan_desc: "리스크 관리는 정상적인 업무 활동 과정에서 리스크 관리를 고려하도록 각 회사의 절차와 충분히 통합될 것입니다.",
    application:"응용 프로그램",
    application_desc:"매년 공식적인 위험 관리 전략을 수립할 것입니다. 이 전략은 회사의 목표를 직접적이고 명확하게 지원할 것입니다. 이 전략은 조직 각급의 지속적인 참여 아래 측정 가능한 시간표와 목표를 가진 충분한 자원 계획을 통해 실시될 것입니다. 저희 시스템은 ISO31000:2009 위험 관리 기준에 부합되고 지속적인 교육 계획을 지원받습니다.교육과 훈련.",
    performance: "성능",
    performance_desc: "우리 리스크 관리의 성공은 회사 목표에 대한 영향, 감사, 연간 리스크 관리 심사, 지속적인 리스크 데이터 수집과 리스크 평가 모델을 통해 평가될 것입니다.",
    acceptance: "검수 기준",
    acceptance_desc: "높은 위험, 극단적인 위험 및/또는 전략적 위험은 고급 관리층의 행동을 통해 통제되고 문서로 기록된 치료 전략을 지정합니다. 중간 위험은 특정한 관리 책임에 맡기고 낮은 위험은 일반적인 절차를 통해 관리합니다.",
    documentation: "문서",
    documentation_desc: "리스크 관리 과정의 각 단계는 적당한 문서 기록, 특히 의사결정과 리스크 처리가 있습니다. 각 프로젝트와 팀은 리스크 등록을 유지하고 기업 리스크는 전략 리스크 데이터베이스에 기록됩니다.",
    responsibilities: "책임",
    responsibilities_desc_1: "리스크 관리는 핵심 업무 기능이자 일상적인 활동의 구성 부분입니다. 개인으로서 우리는 리스크를 관리하는 데 역할을 발휘하고 있으며 각급 직원들은 그 작업장의 리스크 관리 시스템을 이해하고 실시할 책임이 있습니다.",
    responsibilities_desc_2:"각급 관리자와 지도자는 그 직책 범위 내에서 협의된 리스크 관리 정책, 지도 방침과 전략을 응용하고 리스크 관리와 각급 정상적인 활동 과정이 충분히 통합되고 고려되도록 확보해야 한다. 이를 통해 알 수 있듯이 리스크 관리 절차, 효과적인 자원 분배, 효과적인 리스크 관리와 리스크 관리에 적극적으로 참여해야 한다.그리고 위험을 모든 회의의 첫 번째 의사일정 항목으로 삼는다.",
    responsibilities_desc_3: "부서 매니저는 리스크 관리 지도위원회에 리스크와 처리 계획의 진전을 매달 보고하고 전략 또는 극단적인 리스크를 제때에 보고하며 리스크 관리 프레임워크의 실시를 추진하고 매니저가 필요한 기능, 지도와 도구를 갖추도록 확보한다.",
    responsibilities_desc_4: "CRO(Chest Rise Officer)리스크 관리 틀을 제정하고 조율하며 발표하는 것을 책임진다.이것은 교육 계획을 제정하고 기록을 식별, 감시, 보고할 수 있는, 새로운 또는 현재 발생하고 있는 위험을 식별할 수 있는 관리 시스템을 실시하는 것을 포함한다.수석 리스크 관리관은 리스크 관리 절차를 심사하고 관건적인 전략 리스크를 모니터링하고 보고한다.",
}
export default kr;