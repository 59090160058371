import React from "react";
import Footer from '../components/Footer';
import Header from "../components/Header";
import strings from "../components/Language";
import './../App.css';
import Web3 from "web3";
import {NODE_URL } from './../config/node';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
        }
        this.signupPopup = React.createRef();
    }

    async componentDidMount() {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        if (window.ethereum) {
            const web3 = new Web3(window.ethereum || NODE_URL);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                
            }
        }
    }

    render() {
        return (
            <>
                <Header />
                <div className="banner">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col">
                                <h1>{strings.take_of_control}<br/> {strings.your_finance_with}<br/> <span>{strings.wall_street_wolves}</span></h1>
                                <p>{strings.a_secure}-<br/>{strings.generate_wallet}</p>
                                <a href="/package" className="btn btn-primary">{strings.get_started}</a>
                            </div>
                            <div className="col">
                                <img src="assets/images/emblem.png" alt={strings.wall_street_wolves}/>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="earn">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col mb-3">
                                <img src="assets/images/stk.png" alt=""/>
                            </div>
                            <div className="col ps-5">
                                <h1>{strings.earn_upto}<br/> {strings.interest}</h1>
                                <p>{strings.dont_just_let_your}</p>
                                <a href="/package" className="btn btn-dark">{strings.create_account}</a>
                            </div>
                        </div>
                        <div className="traders mt-5">
                            <div className="row align-items-center center">
                                <div className="col">
                                    <figure>
                                        <img src="assets/images/btc_echo.png" alt=""/>
                                    </figure>
                                </div>
                                <div className="col">
                                    <figure>
                                        <img src="assets/images/cointele.png" alt=""/>
                                    </figure>
                                </div>
                                <div className="col">
                                    <figure>
                                        <img src="assets/images/fm.png" alt=""/>
                                    </figure>
                                </div>
                                <div className="col">
                                    <figure>
                                        <img src="assets/images/ns.png" alt=""/>
                                    </figure>
                                </div>
                                <div className="col">
                                    <figure>
                                        <img src="assets/images/yf.png" alt=""/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="key">
                        <div className="row align-items-center">
                            <div className="col order-md-2 center">
                                <figure>			
                                    <img src="assets/images/grh.png" alt=""/>
                                </figure>
                            </div>
                            <div className="col order-md-1">
                                <h1>{strings.your_key}<br/> {strings.crypto_market}</h1>
                                <p>{strings.unlock_eme}</p>
                                <a href="/package" className="btn btn-dark">{strings.get_started}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col explore py-5">
                            <div className="balls">
                                <img src="assets/images/balls.png" alt=""/>
                            </div>
                            <div className="row">
                                <div className="col offset-md-2">
                                    <figure className="my-4">
                                        <img src="assets/images/tab.png" alt=""/>
                                    </figure>
                                    <h3>{strings.exp_arena}</h3>
                                    <p>{strings.check_our_blog}</p>
                                    <a href="/package" className="btn btn-primary">{strings.get_started}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col px-0">
                            <ul className="benefit">
                                <li>
                                    <span>{strings.t1}</span>
                                    <p>{strings.tt1}<br/> {strings.ttt1}</p>
                                </li>
                                <li>
                                    <span>{strings.t2}</span>
                                    <p>{strings.tt2}<br/> {strings.ttt2}</p>
                                </li>
                                <li>
                                    <span>{strings.t3}</span>
                                    <p>{strings.tt3}</p>
                                </li>
                                <li>
                                    <span>{strings.t4}</span>
                                    <p>{strings.tt4}<br/> {strings.ttt4}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="features">
                        <div className="row align-items-center">
                            <div className="col center">
                                <figure>
                                    <img src="assets/images/art_01.png" alt=""/>
                                </figure>
                            </div>
                            <div className="col">
                                <h1>{strings.rapid_real}<br/> {strings.execution}</h1>
                                <p>{strings.in_the_volatile}</p>
                                <a href="/package" className="btn btn-dark">{strings.create_account}</a>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col order-md-2 center">
                                <figure>
                                    <img src="assets/images/art_02.png" alt=""/>
                                </figure>
                            </div>
                            <div className="col order-md-1">
                                <h1>{strings.maximize}<br/> {strings.your_passive_profits}</h1>
                                <p>{strings.stacke_your_fund}</p>
                                <a href="/package" className="btn btn-dark">{strings.get_started}</a>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col center">
                                <figure>
                                    <img src="assets/images/art_03.png" alt=""/>
                                </figure>
                            </div>
                            <div className="col">
                                <h1>{strings.putting_your}<br/> {strings.security_first}</h1>
                                <p>{strings.fully_eu}</p>
                                <a href="/package" className="btn btn-dark">{strings.start_now}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
export default Home;
