
import React from "react";
import { BrowserRouter, Route, Routes, } from 'react-router-dom';

import Home from './pages/index';
import Package from './pages/package';
import Arbitrage from './pages/arbitrage';
import Support from './pages/support';
import Company from './pages/company';
import RiskPolicy from './pages/riskpolicy';
import FAQ from './pages/faq';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
class App extends React.Component {
    render(){
        return (
            <>
                <BrowserRouter>
                    <ToastContainer theme="dark" style={{fontFamily:"Nunito"}}/>
                    <Routes>
                        <Route exact  path="/" element={<Home/>}/>
                        <Route path="/package" element={<Package/>}/>
                        <Route path="/arbitrage" element={<Arbitrage/>}/>
                        <Route path="/support" element={<Support/>}/>
                        <Route path="/company" element={<Company/>}/>
                        <Route path="/risk-policy" element={<RiskPolicy/>}/>
                        <Route path="/faq" element={<FAQ/>}/>
                    </Routes>
                </BrowserRouter>
            </>
        );
    }
}
export default App;