import React from "react";
import Footer from '../components/Footer';
import Header from "../components/Header";
import strings from "../components/Language";
import './../App.css';
import Web3 from "web3";
import {NODE_URL } from './../config/node';

class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
        }
        this.signupPopup = React.createRef();
    }

    async componentDidMount() {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        if (window.ethereum) {
            const web3 = new Web3(window.ethereum || NODE_URL);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                
            }
        }
    }

    render() {
        return (
            <>
                <Header />
                <section className="common">
                    <div className="container">
                        <div className="row">
                            <div className="col offset-md-5">
                                <p>{strings.line_1}</p>
                                <p>{strings.line_2}</p>
                                <p>{strings.line_3}</p>
                                <p>{strings.line_4}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}
export default Company;
