const en = {
    confirm: "Confirm",
    coming_soon: "Coming Soon",
    processing: "Transaction submitted. Please wait",
    error: "Error",
    success: "Success",
    close: "Close",
    language: "Language",
    language_changed: "Language Changed",
    install_metamask: "Install Metamask",
    please_connect: "Please Connect Wallet",
    loading: "Processing...",
    approve:"Approve",
    rejected:"Rejected",
    running:"Running",

    //Header
    package: "Package",
    arbitrage: "Arbitrage",
    transaction: "Transaction",
    connect: "Connect",
    connected: "Connected",
    connect_wallet: "CONNECT WALLET",

    //Home Page
    take_of_control: "Safe and convenient",
    your_finance_with:"Grow your wealth",
    wall_street_wolves:"Wolves of Wall Street",
    a_secure: "A secure global automated, EU-authorized digital financial services platform",
    generate_wallet:"Prophecy Robot WSW",
    get_started: "Start Using",
    earn_upto: "Earn up to 400%",
    interest:"Return!",
    dont_just_let_your: "Don't just let your USDT and WOLF cryptocurrencies sit idle. Use WSW language robot arbitrage service to earn super high yield",
    create_account:"Start the service",
    your_key:"Your key",
    crypto_market:"Crypto Market",
    unlock_eme:"Trade securely on WSW's market-leading, licensed and regulated exchange to unlock emerging opportunities quickly and reliably.",
    exp_arena:"Explore the crypto asset arena",
    check_our_blog:"Learn about topics related to blockchain, DEFI, and crypto-asset competitive gaming. Read our guide to start your digital asset competitive journey. (Start Competitive) Here's a link to go to your game system.",
    t1:"4 Weeks Ago",
    tt1:"Bear market or bull market, you don't have to panic, wsw brings you different income options",
    ttt1:"from the bear market",
    t2:"2 Month Ago",
    tt2:"Decentralized autonomy",
    ttt2:"Organization (DAO) Explained",
    t3:"3 Month Ago",
    tt3:"Top DeFi Cryptocurrencies in 2022",
    t4:"3 个月前",
    tt4:"Top Crypto Trends for 2022: Gaming and",
    ttt4:"Metaverse",
    rapid_real:"fast, real-time",
    execution:"Execution",
    in_the_volatile:"In volatile cryptocurrency exchanges, every second counts. Our highly responsive technology can react instantly while executing high volume trades simultaneously.",
    maximize:"最大化",
    your_passive_profits:"your passive profit",
    stacke_your_fund:"Invest your money through our decentralized yield oracle bot arbitrage program and trade with our automated arbitrage trading system to enjoy an annualized rate of return unmatched anywhere in the industry.",
    putting_your:"investment principles",
    security_first:"The security of investment principal and income funds is the first factor",
    fully_eu:"Fully EU licensed and regulated, ArbiSmart implements the strictest technological protocols to ensure account integrity, safeguarding the privacy and security of all automated transactions.",
    start_now:"Start Now",

    //Arbitrage
    what_is_arbitrage: "What is arbitrage?",
    arbitrage_involves: "Arbitrage involves buying an asset on one market and selling it on another to profit from a price difference between the two. Arbitrage is widely considered to offer an attractive investment opportunity as it tends to provide healthy returns while exposing the investor to minimal risk.",
    what_is_carbitrage: "What is crypto arbitrage?",
    take_adv: "Crypto arbitrage takes advantage of temporary price inefficiencies - brief intervals where a coin is available at different prices simultaneously. The coin is bought on the exchange where the price is lowest, then sold on the exchange where the price is highest to generate a profit.",
    take_adv1: "WSW is a fully EU regulated, automated system, which scans thirty-five exchanges at once, 24/7",
    take_adv2: "Secure and reliable, our advanced proprietary technology enables you to invest with peace of mind",
    take_adv3: "The platform generates industry-high returns, of up to 45% a year depending on the size of the investment",
    why_become_an_arbitrage:"Why become an arbitrage",
    investor_with:"investor with",
    s1:"Unmatched profit potential",
    ss1:"Enjoy the opportunity to earn unparalleled passive profits and industry-leading interest rates on your fiat and crypto capital",
    s2:"State-of-the-art technology",
    ss2:"Benefit from the cryptocurrency markets in real time, with advanced tech capable of processing a mass of data at lightning speed",
    s3:"EU licensed and regulated",
    ss3:"Invest with peace of mind, with an exceptionally secure, licensed platform, compliant with the strictest regulatory standards",
    s4:"Access via any device",
    ss4:"Manage your portfolio from home or on the go, accessing your ArbiSmart account, at any time, via your computer, tablet or mobile",
    s5:"Expert 24/7 assistance",
    ss5:"Receive first-className service and support, around the clock, from our experienced, global team of crypto market professionals",
    s6:"Support for fiat and crypto",
    ss6:"Take advantage of lucrative crypto exchange, arbitrage, and wallet opportunities using a wide selection of fiat and cryptocurrencies",

    //Support
    contact_us:"Contact us",
    get_in_touch_1:"Get in touch with our expert team of industry specialists and receive personal, professional",
    get_in_touch_1:"guidance twenty-four hours a day, from our global support staff, via email and live chat.",
    open_chat:"open chat",
    email:"E-mail",
    via_email:" Contact our team via e-mail, at any time, at support@wallstreetwolves.com",
    live_chat:"Wall Street Wolves expert, global professionals are available, 24/7, via live chat",

    // Package
    status:"Status",
    arbitrage_amount:"Arbitrage Amount",
    arbi_colelcted:"Arbi Collectable",
    collect_roi:"Collect ROI",
    end:"End",
    your_wallet:"Your Wallet",
    min:"Min",
    max:"Max",
    apy:"APY",
    purchase_now:"Purchase",
    node_purchase:"Package Purchase",
    usdt_amount:"USDT Amount",
    usd_balance:"USDT Balance",
    wolf_balance:"WOLF Balance",
    collect:"Collect",
    approve_wolf_notice:" amount of WOLF is needed as administration fees. Do you approve?",
    approve_usdt_notice:"Wolves Of Wall Street Will be deducting {0} amount of USDT from your wallet. Do you Approve? ",
    submit:"Submit",
    enter_usdt_amount:"Please Enter USDT Amount",
    next:"Next",
    confirm_purchase:"Confirm Purchase?",
    purchase_complete:"Purchase Success",
    purchase_fail:"Purchase Fail",
    view_transaction:"View Transaction in explorer",
    pacakge_1_limit:"Package-A USDT must between 100 and 499",
    pacakge_2_limit:"Package-B USDT must between 500 and 1999",
    pacakge_3_limit:"Package-C USDT must between 2000 and 9999",

    // Footer
    note_1:"This website is operated by Wall Street Wolves a company that is registered in the Asia and authorized by the FCIS to provide virtual currency services, with the registered",
    arbitrage:"Arbitrage",
    accounts:"Accounts",
    partnership:"Partners",
    support:"Support",
    company:"Company",
    careers:"Careers",
    how_it_work:"How it Work",
    roadmap:"Roadmap",
    rsp:"Risk Policy",
    rp:"Refund Policy",
    kyc:"KYC Policy",
    am:"AML Policy",
    tc:"Terms and Conditions",
    pp:"Privacy Policy",
    quthorizations:"Authorizations",
    blog:"Blog",
    faq:"FAQ",
    how_to_operate:"How to operate?",

    //Util
    eth_main_net: "ETH Main Net",
    bsc_main_net: "BSC Main Net",
    bsc_test_net: "BSC Test Net",
    unknown_net: "Unknown Net",
    ongoing: "Ongoing",
    expired: "Expired",
    collected: "Collected",

    //Company
    line_1:"WallStreet Wolves is a new generation of all-digital funds jointly led by Peter, John, Julian Jesse, and Anthony, well-known partners of international financial fund companies. A new digital empire is about to rise.",
    line_2:"The purpose of WallStreet Wolves is to create a global all-attribute digital fund. A financial service platform, serving global digital currency enthusiasts and providing them with the most professional and high-quality digital financial services, WallStreet Wolves Digital Fund will first launch a 100% decentralized trading prediction trading robot profit service system, and issue Eco-coins are used for the development of the overall digital financial ecosystem.",
    line_3:"In the future, WallStreet Wolves will derive many businesses around digital finance, including digital asset investment funds, digital asset lending, digital asset hedge funds, big data fund pools, and digital asset tourism real estate Metaverse Fund Pools And many other businesses, WallStreet Wolves has the strongest technical team, the best fund partners and a huge global network of relationships.",
    line_4:"With reasonable financial service business closed loop, high return on investment will surely make every user in the world. Achieve wealth growth. WallStreet Wolves takes you to a better world of finance.",

    //FAQ
    q1:"Q1. What platform is Wall Street Wolves?",
    a1:"A1: Wall Street Wolves is a global and international new-generation digital financial service platform. It will derive many businesses around digital finance, including digital asset investment funds, digital asset lending, digital asset hedge funds, big data fund pools, and digital asset tourism real estate. Metaverse Fund Pool and many other businesses.",
    q2:"Q2. How does Wall Street Wolves participate?",
    a2:"A2: Purchase Wall Street Wolves platform tokens WOLF and USDT through the Bing Decentralized Exchange under Binance Smart Chain and enter the Wall Street Wolves platform to choose the service package that suits you, and you can start the prediction robot arbitrage service.",
    q3:"Q3. How does Wall Street Wolves profit?",
    a3:"A3: Wall Street Wolves predicts that the robot arbitrage service will carry out arbitrage according to the service package selected by the user, and the daily arbitrage income will be settled in real time according to the agreement in the user’s decentralized wallet. The daily rate of return varies from 1.2% to 4%.",
    q4:"Q4. Are Wall Street Wolves safe?",
    a4:"A4: Wall Street Wolves is a global digital financial service platform approved and operated by European and American financial institutions. It is co-founded by many well-known fund investment partners. Wall Street Wolves has the strongest technical team, the best fund partners and a large number of global funds. The relationship network is safe and reliable.",

    //Risk Policy
    risk_policy_management:"Risk Management Policy",
    policy:"Policy",
    policy_desc:"We are committed to a systematic and comprehensive approach to the effective management of potential opportunities and adverse effects by achieving best practice in risk management.",
    philosophy:"Philosophy",
    philosophy_desc:"WSW embraces intelligent risk-taking and recognizes that risks can have both positive and negative consequences.",
    objectives:"Objectives",
    objectives_desc:"Risk management helps us achieve our objectives, operate effectively and efficiently, protect our people and assets, make informed decisions, and comply with applicable laws and regulations.",
    business_plan:"Business Planning",
    business_plan_desc:"Risk Management will be fully integrated with corporate processes at all levels to ensure it is considered in the normal course of business activities.",
    application:"Application",
    application_desc:"A formal Risk Management Strategy will be developed each year, which directly and demonstrably supports corporate objectives. It will be implemented with the sustained involvement of all levels of the organization via adequately resourced plans with measurable timelines and objectives. Our systems are aligned with ISO31000:2009 Risk Management Standard and supported by an ongoing program of education and training.",
    performance:"Performance",
    performance_desc:"The success of our risk management will be measured by its impact on our corporate objectives, by audits, annual risk management review, the ongoing collection of risk data and the evaluation of risk models.",
    acceptance:"Acceptance Criteria",
    acceptance_desc:"High, Extreme, and/or Strategic risks are controlled through senior management action with documented treatment strategies assigned. Medium risks are assigned specific management responsibility, while Low risks are managed through routine procedures.",
    documentation:"Documentation",
    documentation_desc:"Each stage of the risk management process is appropriately documented, particularly decisions and risk treatments.  Individual projects and groups maintain risk registers, while enterprise risks are recorded in the strategic risk database.",
    responsibilities:"Responsibilities",
    responsibilities_desc_1:"Risk management is a core business skill and an integral part of day-to-day activity. As individuals, we all play our part in managing risk, and staff at all levels are responsible for understanding and implementing risk management systems in their workplace.",
    responsibilities_desc_2:"Managers and leaders at all levels are responsible for applying agreed risk management policy, guidelines, and strategies in their area of responsibility and are expected to ensure risk management is fully integrated with and considered in the normal course of activities at all levels. Visible commitment requires active participation in risk management processes, effective resource allocation, and making ‘risk’ the first agenda item at all meetings.",
    responsibilities_desc_3:"Divisional Managers are responsible for reporting the progress of risks and treatment plans to the Risk Management Steering Committee every month, reporting strategic or Extreme risks in a timely fashion, driving the implementation of the Risk Management Framework, and ensuring that managers are equipped with the necessary skills, guidance, and tools",
    responsibilities_desc_4:"The Chief Risk Officer (CRO) is responsible for development, coordination, and promulgation of the Risk Management Framework. This includes developing training programs and implementing management systems that are capable of identifying, monitoring, and reporting documented, new or emerging risks. The CRO is also responsible for the review of the Risk Management process, monitoring and reporting key strategic risks.",
};

export default en;