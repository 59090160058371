import React from "react";
import Footer from '../components/Footer';
import Header from "../components/Header";
import strings from "../components/Language";
import './../App.css';
import Web3 from "web3";
import $ from 'jquery';
import confirm from 'jquery-confirm';
import { alertMessage, successMessage } from "../components/Util";
import { toast } from 'react-toastify';
import { USDT_ADDRESS, USDT_ABI } from './../config/usdt';
import { WOLF_ADDRESS, WOLF_ABI } from './../config/wolf';
import { NODE_ADDRESS, NODE_ABI,NODE_URL,NODE_EXPLORER } from './../config/node';

class  Package extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account:null,
            wolf_approve_onpress:0,
            usdt_approve_onpress:0,
            purchase_onpress:0,
            onpress:0,
            is_approve_usdt: 0,
            is_approve_wolf: 0,
            plan:1,

            my_package_list:[],
            usdt_amount:0,

            usdt_balance:0,
            wolf_balance:0,
            wolf_price:0,
            step:1,
            txnid:"",
            txtStatus:0,
        }
        this.oneday=86400;
    }

    async componentDidMount() {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        if (window.ethereum) {
            const web3 = new Web3(window.ethereum || NODE_URL);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                const web3 = new Web3(window.ethereum || NODE_URL);
                this.web3=web3;
                const Staking = new web3.eth.Contract(NODE_ABI, NODE_ADDRESS);
                let accounts = await web3.eth.getAccounts();
                if (accounts.length) {
                    this.setState({ account: accounts[0] })
                    this.setState({ is_connected: true })
                   
                    //Check For USDT Approve
                    const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
                    const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                    if (web3.utils.fromWei(is_approve, 'ether') >= 10000) {
                        this.setState({ is_approve_usdt: 1 })
                    }
    
                    //Check For WOLF Approve
                    const WOLFTokenContract = new web3.eth.Contract(WOLF_ABI, WOLF_ADDRESS);
                    const is_wolf_approve = await WOLFTokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
                    if (web3.utils.fromWei(is_wolf_approve, 'mwei') >= 10000000) {
                        this.setState({ is_approve_wolf: 1 })
                    }

                    const stake_list = await Staking.methods.user(accounts[0]).call()
                    this.setState({ my_package_list: stake_list });

                    const usd = await Staking.methods.balanceOfUSDT(accounts[0]).call()
                    const wolf = await Staking.methods.balanceOfWOLF(accounts[0]).call()
                    const price = await Staking.methods.getWOLFPrice().call();
                    console.log(price);
                    this.setState({ 
                        usdt_balance: web3.utils.fromWei(usd,'ether'),
                        wolf_balance: wolf>0?(wolf/10000):0,
                        wolf_price: price>0?(price/(10**14)):0,
                    });
                    
                }
            }
        }
    }

    /** 
     * Approve USDT 
     */
    async approveUSDT() {
        if (this.state.is_connected) {
            this.setState({ usdt_approve_onpress: 1 })
            let that = this;
            const web3 = new Web3(window.ethereum);
            const Token = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Token.methods.approve(NODE_ADDRESS, web3.utils.toWei('10000', 'ether')).send(
                    {
                        from: this.state.account,
                    })
                    .on('transactionHash', function (hash) {
                    })
                    .on('receipt', function (receipt) {
                        that.setState({ usdt_approve_onpress: 0, is_approve_usdt: 1,step:4 })
                        resolve(true)
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                    })
                    .on('error', function (error, receipt) {
                        alertMessage(error.message);
                        that.setState({ usdt_approve_onpress: 0 })
                        reject(error.message)
                    });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: strings.processing,
                    success: strings.approved,
                    error: strings.rejected,
                }, {
                position: "top-center",
                autoClose: !1,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
        }
        else {
            alertMessage(strings.please_connect);
        }
    }

    /** 
     * Approve WOLF 
     */
    async approveWOLF() {
        if(this.state.is_connected) {
            this.setState({ wolf_approve_onpress: 1 })
            let that = this;
            const web3 = new Web3(window.ethereum);
            const Token = new web3.eth.Contract(WOLF_ABI, WOLF_ADDRESS);
            
            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Token.methods.approve(NODE_ADDRESS, web3.utils.toWei('5000000000000', 'mwei')).send(
                    {
                        from: this.state.account,
                    })
                    .on('transactionHash', function (hash) {
                    })
                    .on('receipt', function (receipt) {
                        that.setState({  wolf_approve_onpress: 0,is_approve_wolf:1,step:2 })
                        resolve(true)
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                    })
                    .on('error', function (error, receipt) {
                        alertMessage(error.message);
                        that.setState({ wolf_approve_onpress: 0 })
                        reject(error.message)
                    });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: strings.processing,
                    success: strings.approved,
                    error: strings.rejected,
                }, {
                position: "top-center",
                autoClose: !1,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
        }
        else {
            alertMessage(strings.please_connect);
        }
    }

    checkUSDTNext(){
        let plan = this.state.plan;
        let amount = parseFloat(this.state.usdt_amount);
        if(this.checkValidAmount(plan,amount)==false){
            if(plan==1){
                alertMessage(strings.pacakge_1_limit);
            }
            else if(plan==2){
                alertMessage(strings.pacakge_2_limit);
            }
            else if(plan==3){
                alertMessage(strings.pacakge_3_limit);
            }
            return 0;
        }
        this.setState({step:3})
    }

    /**
     * Create New Stake
     */
    async buyNode() {
        let plan = this.state.plan;
        let amount = parseFloat(this.state.usdt_amount);
        if(this.checkValidAmount(plan,amount)==false){
            if(plan==1){
                alertMessage(strings.pacakge_1_limit);
            }
            else if(plan==2){
                alertMessage(strings.pacakge_2_limit);
            }
            else if(plan==3){
                alertMessage(strings.pacakge_3_limit);
            }
            return 0;
        }
        else if (this.state.is_connected) {
            this.setState({ purchase_onpress: 1 })
            let that = this;
            const web3 = new Web3(window.ethereum);
            const Staking = new web3.eth.Contract(NODE_ABI, NODE_ADDRESS);
            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Staking.methods.buy(plan,web3.utils.toWei(amount.toString(), 'ether')).send(
                    {
                        from: this.state.account,
                    })
                    .on('transactionHash', function (hash) {
                    })
                    .on('receipt', function (receipt) {
                        console.log(receipt);
                        that.setState({ purchase_onpress: 0,txnid:receipt.transactionHash,step:5,txtStatus:1})
                        resolve(true);
                        setTimeout(function () {
                            window.location.reload();
                        }, 5000)
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                    })
                    .on('error', function (error, receipt) {
                        alertMessage(error.message);
                        that.setState({ purchase_onpress: 0,step:5,txtStatus:2 })
                        reject(error.message)
                    });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: strings.processing,
                    success: strings.staked,
                    error: strings.rejected,
                }, {
                position: "top-center",
                autoClose: !1,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
        }
        else {
            alertMessage(strings.please_connect);
        }
    }

    checkValidAmount(plan,amount){
        if(plan==1 && amount>=100 && amount<=499 ){
            return true;
        }
        else if(plan==2 && amount>=500 && amount<=1999 ){
            return true;
        }
        else if(plan==3 && amount>=2000 && amount<=9999 ){
            return true;
        }
        return false;
    }

    /**
     * Collect ROI
     * @param {int} _id 
     * @returns 
     */
      async CollectROI(_id) {
        if (this.state.is_connected) {
            this.setState({ onpress: 1 })
            let that = this;
            const web3 = new Web3(window.ethereum);
            const Staking = new web3.eth.Contract(NODE_ABI, NODE_ADDRESS);
            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Staking.methods.collect(_id).send(
                    {
                        from: this.state.account,
                    })
                    .on('transactionHash', function (hash) {

                    })
                    .on('receipt', function (receipt) {
                        that.setState({ onpress: 0 })
                        resolve(true);
                        setTimeout(function () {
                            window.location.reload();
                        }, 5000)
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                    })
                    .on('error', function (error, receipt) {
                        alertMessage(error.message);
                        that.setState({ onpress: 0 })
                        reject(error.message)
                    });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: strings.processing,
                    success: strings.collected,
                    error: strings.rejected,
                }, {
                position: "top-center",
                autoClose: !1,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
            
        }
        else {
            alertMessage(strings.please_connect);
        }
    }

    /**
     * End Contract
     * @param {int} _id 
     * @returns 
     */
    async endContract(_id) {
        if (this.state.is_connected) {
            this.setState({ onpress: 1 })
            let that = this;
            const web3 = new Web3(window.ethereum);
            const Staking = new web3.eth.Contract(NODE_ABI, NODE_ADDRESS);

            if (this.state.is_registerd === false) {
                alertMessage(strings.account_not_registered);
                this.setState({ onpress: 0 })
                return false;
            }
            else {
                const resolveAfter3Sec = new Promise((resolve, reject) => {
                    Staking.methods.stopContract(_id).send(
                        {
                            from: this.state.account,
                        })
                        .on('transactionHash', function (hash) {

                        })
                        .on('receipt', function (receipt) {
                            that.setState({ onpress: 0 })
                            resolve(true);
                            setTimeout(function () {
                                window.location.reload();
                            }, 5000)
                        })
                        .on('confirmation', function (confirmationNumber, receipt) {
                        })
                        .on('error', function (error, receipt) {
                            alertMessage(error.message);
                            that.setState({ onpress: 0 })
                            reject(error.message)
                        });
                });
                toast.promise(
                    resolveAfter3Sec,
                    {
                        pending: strings.processing,
                        success: strings.collected,
                        error: strings.rejected,
                    }, {
                    position: "top-center",
                    autoClose: !1,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: 0,
                }
                )
            }
        }
        else {
            alertMessage(strings.please_connect);
        }
    }

    /**
     * Collect Earning USDT 
     * @returns 
     */
    async collectUSDT() {
        if (this.state.is_connected) {
            this.setState({ onpress: 1 })
            let that = this;
            const web3 = new Web3(window.ethereum);
            const Staking = new web3.eth.Contract(NODE_ABI, NODE_ADDRESS);
            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Staking.methods.collectUSDT().send(
                {
                    from: this.state.account,
                })
                .on('transactionHash', function (hash) {

                })
                .on('receipt', function (receipt) {
                    that.setState({ onpress: 0 })
                    resolve(true);
                    setTimeout(function () {
                        window.location.reload();
                    }, 5000)
                })
                .on('confirmation', function (confirmationNumber, receipt) {
                })
                .on('error', function (error, receipt) {
                    alertMessage(error.message);
                    that.setState({ onpress: 0 })
                    reject(error.message)
                });
            });

            toast.promise(
                resolveAfter3Sec,
                {
                    pending: strings.processing,
                    success: strings.collected,
                    error: strings.rejected,
                }, {
                position: "top-center",
                autoClose: !1,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
        }
        else{
            alertMessage(strings.please_connect);
        }
    }

    /**
     * Collect Earning WOLF 
     * @returns 
     */
     async collectWOLF(){
        if (this.state.is_connected) {
            this.setState({ onpress: 1 })
            let that = this;
            const web3 = new Web3(window.ethereum);
            const Staking = new web3.eth.Contract(NODE_ABI, NODE_ADDRESS);
            const resolveAfter3Sec = new Promise((resolve, reject) => {
                Staking.methods.collectWOLF().send(
                    {
                        from: this.state.account,
                    })
                    .on('transactionHash', function (hash) {

                    })
                    .on('receipt', function (receipt) {
                        that.setState({ onpress: 0 })
                        resolve(true);
                        setTimeout(function () {
                            window.location.reload();
                        }, 5000)
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                    })
                    .on('error', function (error, receipt) {
                        alertMessage(error.message);
                        that.setState({ onpress: 0 })
                        reject(error.message)
                    });
            });
            toast.promise(
                resolveAfter3Sec,
                {
                    pending: strings.processing,
                    success: strings.collected,
                    error: strings.rejected,
                }, {
                position: "top-center",
                autoClose: !1,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: 0,
            })
            
        }
        else {
            alertMessage(strings.please_connect);
        }
    }

    getPacakgeName(_pack){
        if(_pack==1){
            return "A";
        }
        else if(_pack==2){
            return "B";
        }
        return "C";
    }

    getPayoutPer(_pack){
        if(_pack==1){
            return 1.2;
        }
        else if(_pack==2){
            return 2.4;
        }
        return 4;
    }

    getWolf(_pack){
        if(_pack==1){
            return 50/this.state.wolf_price;
        }
        else if(_pack==2){
            return 200/this.state.wolf_price;
        }
        return 500/this.state.wolf_price;
    }

    /**
     * Get Collectable Amount
     * @param {*} place 
     * @returns 
     */
     getCollectibleAmount(place) {
        let diff = parseFloat(new Date().getTime() / 1000) - parseFloat(place.collected_at);
        if (diff >= this.oneday) {
            let mod = diff % this.oneday;
            let payout = diff - mod;
            let sets = payout / this.oneday;
            const web3 = new Web3(window.ethereum);
            let plan_amount=parseFloat(web3.utils.fromWei(place.amount, 'ether'));
            let one_payout=(plan_amount*this.getPayoutPer(place.plan))/100;
            let roi = one_payout * sets;
            return roi;
        }
        return 0;
    }

    render() {
        let that=this;
        return (
            <>
                <Header />
                <section className="main py-4">
                    <div className="container">
                        <div className="profile">
                            <div className="row">
                                <div className="col-12">
                                    <h5>{strings.your_wallet} : {this.state.account} </h5>
                                </div>
                            </div>
                            <div className="row gx-3">
                                <div className="col col-md-3" key={190}>
                                    <div className="panel">
                                        <div className="detail">
                                            <p className="">{strings.usd_balance} {this.state.usdt_balance}</p>
                                            <a onClick={()=>this.collectUSDT()} className="red" style={{cursor:"pointer"}}>{strings.collect}</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="col col-md-3" key={195}>
                                    <div className="panel">
                                        <div className="detail">
                                            <p className="">{strings.wolf_balance} {this.state.wolf_balance}</p>
                                            <a onClick={()=>this.collectWOLF()} className="red" style={{cursor:"pointer"}}>{strings.collect}</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row gx-3">
                                <div className="col col-md-3">
                                    <div className="panel">
                                        <figure>
                                            <img src="assets/images/pack.png" alt=""/>
                                        </figure>
                                        <div className="detail">
                                            <p className="m-0"><strong>{strings.package} A</strong><br/>
                                            {strings.apy}: 100%<br/>
                                            {strings.min}. 100 USDT<br/>
                                            {strings.max}: 499 USDT <a href="#" className="float-end" data-bs-toggle="modal" data-bs-target="#buy_placement" onClick={()=>this.setState({plan:1})}>{strings.purchase_now}</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-md-3">
                                    <div className="panel">
                                        <figure>
                                            <img src="assets/images/pack.png" alt=""/>
                                        </figure>
                                        <div className="detail">
                                            <p className="m-0"><strong>{strings.package} B</strong><br/>
                                            {strings.apy}: 150%<br/>
                                            {strings.min}. 500 USDT<br/>
                                            {strings.max}: 1999 USDT <a href="#" className="float-end" data-bs-toggle="modal" data-bs-target="#buy_placement" onClick={()=>this.setState({plan:2})}>{strings.purchase_now}</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-md-3">
                                    <div className="panel">
                                        <figure>
                                            <img src="assets/images/pack.png" alt=""/>
                                        </figure>
                                        <div className="detail">
                                            <p className="m-0"><strong>{strings.package} C</strong><br/>
                                            {strings.apy}: 240%<br/>
                                            {strings.min}. 2000 USDT<br/>
                                            {strings.max}: 9999 USDT <a href="#" className="float-end" data-bs-toggle="modal" data-bs-target="#buy_placement" onClick={()=>this.setState({plan:3})}>{strings.purchase_now}</a></p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="main py-4">
                    <div className="container">
                        <div className="profile">
                            <div className="row gx-3">
                                {
                                    this.state.my_package_list.length>0?this.state.my_package_list.map(function(d,i){
                                        return(
                                            <div className="col col-md-3" key={i+100}>
                                                <div className="panel">
                                                    <div className="detail">
                                                        <p className="m-0">{strings.package} {that.getPacakgeName(d.plan)}<br/>
                                                        {strings.status}: <span className="red">{d.status=="1"?strings.running:strings.collected}</span><br/>
                                                        {strings.arbitrage_amount}: {that.web3.utils.fromWei(d.amount, 'ether')} USDT<br/>
                                                        {strings.arbi_colelcted}: {that.getCollectibleAmount(d)}<br/>
                                                        <a onClick={()=>that.CollectROI(i)} style={{cursor:"pointer"}}>{strings.collect_roi}</a>
                                                        {d.status==1 && d.plan==1?<a onClick={()=>that.endContract(i)} className="ms-2 red" style={{cursor:"pointer"}}>{strings.end}</a>:null}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }):null
                                }
                                
                            </div>
                        </div>
                    </div>
                </section>

                <div className="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" id="buy_placement" tabIndex="-1" aria-labelledby="Buy Join" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">{strings.node_purchase}</h5>
                                <button type="button" className="btn btn-sm close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                               
                                <div className="form-group text-center">
                                    {
                                        this.state.step==1?
                                            <>
                                                <h6>{this.getWolf(this.state.plan)}{strings.approve_wolf_notice}</h6>
                                                {
                                                    this.state.is_approve_wolf === 0?
                                                        this.state.wolf_approve_onpress ?
                                                            <button type="button" className="btn btn-open">
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                <span className="">&nbsp;{strings.loading}</span>
                                                            </button>
                                                            :
                                                            <button type="button" className="btn btn-open" onClick={() => this.approveWOLF()}>{strings.approve} WOLF</button>
                                                        : 
                                                        <button type="button" className="btn btn-open" onClick={() => this.setState({step:2})}>{strings.next}</button>
                                                }
                                            </>
                                        :null
                                    }
                                    {
                                        this.state.step==2?
                                            <>
                                                <h6>{strings.enter_usdt_amount}</h6>
                                                <div className="form-group text-center">
                                                    <input type="text" className="form-control" id="recipient-name" onChange={(e) => this.setState({ usdt_amount: e.target.value })} placeholder={strings.usdt_amount}/>
                                                </div>
                                                <button type="button" className="btn btn-open mt-2" onClick={() => this.checkUSDTNext()}>{strings.submit}</button>
                                            </>
                                        :null
                                    }
                                    {
                                        this.state.step==3?
                                            <>
                                                <h6>{strings.formatString(strings.approve_usdt_notice,this.state.usdt_amount)}</h6>
                                                {
                                                   this.state.is_approve_usdt === 0 ?
                                                        this.state.usdt_approve_onpress ?
                                                            <button type="button" className="btn btn-open">
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                <span className="">&nbsp;{strings.loading}</span>
                                                            </button>
                                                            :
                                                            <button type="button" className="btn btn-open" onClick={() => this.approveUSDT()}>{strings.approve} USDT</button>
                                                        : 
                                                        <button type="button" className="btn btn-open" onClick={() => this.setState({step:4})}>{strings.next}</button>
                                                }
                                            </>
                                        :null
                                    }

                                    {
                                        this.state.step==4?
                                            <>
                                                <h6>{strings.confirm_purchase}</h6>
                                                {
                                                    this.state.is_approve_usdt===1 && this.state.is_approve_wolf===1?
                                                        this.state.purchase_onpress  ?
                                                            <button type="button" className="btn btn-open">
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                <span className="">&nbsp;{strings.loading}</span>
                                                            </button>
                                                            :
                                                            <button type="button" className="btn btn-open" onClick={() => this.buyNode()}>{strings.confirm}</button>
                                                        : 
                                                        null
                                                }
                                            </>
                                        :null
                                    }
                                    {
                                        this.state.step==5?
                                            <>
                                                {
                                                    this.state.txtStatus==1?<img src="assets/images/success.svg" alt="" style={{height:200}}/>:null
                                                }
                                                {
                                                    this.state.txtStatus==1?<h6>{strings.purchase_complete}</h6>:null
                                                }

                                                {
                                                    this.state.txtStatus==2?<img src="assets/images/error.svg" alt="" style={{height:200}}/>:null
                                                }
                                                {
                                                    this.state.txtStatus==2?<h6>{strings.purchase_fail}</h6>:null
                                                }
                                                <b><a target={"_blank"} href={NODE_EXPLORER+"tx/"+this.state.txnid}>{strings.view_transaction}</a></b>
                                            </>
                                        :null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
export default Package;
