const th = {
    
    confirm: "ยืนยันยืนยันว่า",
    coming_soon: "กำลังจะมาเร็วๆนี้",
    processing: "ธุรกรรมมีความมุ่งมั่นโปรดรอสักครู่",
    error: "ความผิดพลาดความผิดพลาด",
    success: "ความสำเร็จความสำเร็จ",
    close: "ปิดปิด",
    language: "ภาษาภาษาภาษา",
    language_changed: "ภาษาเปลี่ยนภาษา",
    install_metamask: "คุณสามารถติดตั้งเมนูสำหรับ ของคุณ",
    please_connect: "กรุณาเชื่อมต่อกระเป๋าสตางค์ของคุณ",
    loading: "โหลดข้อมูลการจัดการ",
    approve: "อนุมัติอนุมัติ",
    rejected: "การปฏิเสธการปฏิเสธ",
    running: "วิ่งวิ่งวิ่ง",

     //ตัดต่อม
     package: "พัสดุ",
     arbitrage: "arbitrage",
     transaction: "ข้อตกลง",
     connect: "การเชื่อมต่",
     connected: "เชื่อมต่อแล้",
     connect_wallet: "เชื่อมต่อกับกร",

    //หน้าแรกของตับ
    take_of_control: "คุณสามารถบอกได้ว่ามันปลอดภัยและสะดวก",
    your_finance_with: "คุณจะได้รับเงินเพิ่มโดย",
    wall_street_wolves: "หมาป่าวอลล์สตรีทหมาป่าวอลล์สตรีท",
    a_secure: "การรักษาความปลอดภัยระดับโลกอัตโนมัติแพลตฟอร์มบริการทางการเงินดิจิตอลที่ได้รับอนุญาตจากสหภาพยุโรป",
    generate_wallet: "ถ้าคุณมีข้อมูลอื่นๆใดๆเกี่ยวกับเรื่องนี้กรุณาเพิ่มฟรี",
    get_started: "ได้รับการเริ่มต้นสำหรับ",
    earn_upto: "ได้รับกล้าที่จะถึง 400 เปอร์เซ็นต์",
    interest: "ดอกเบี้ยคืนมา",
    dont_just_let_your: "อย่าปล่อยให้สกุลเงินที่เข้ารหัสของคุณ USDT และ WOLF ไม่ได้ใช้งานใช้ WSW หุ่นยนต์ภาษา arbitrage บริการได้รับผลตอบแทนสูงสุด",
    create_account: "สร้างบัญชีผู้ใช้สำหรับบริการเริ่มต้น",
    your_key: "กุญแจของคุณกุญแจของคุณ",
    crypto_market: "สำหรับการเข้ารหัสตลาด",
    unlock_eme: "การรักษาความปลอดภัยการซื้อขายในตลาดหลักทรัพย์ที่ได้รับอนุญาตและกฎระเบียบใน WSW เพื่อปลดล็อคโอกาสใหม่ได้อย่างรวดเร็วและเชื่อถือได้",
    exp_arena: "สำรวจสินทรัพย์ที่เข้ารหัสเวที",
    check_our_blog: "เรียนรู้เกี่ยวกับหัวข้อที่เกี่ยวข้องกับบล็อกโซ่ defi และเกมการแข่งขันสินทรัพย์การเข้ารหัสอ่านคู่มือของเราและเริ่มต้นการเดินทางของคุณในสินทรัพย์ดิจิตอล",
    t1: "เมื่อสัปดาห์ที่แล้ว",
    tt1: "ตลาดหมีหรือตลาดกระทิงที่คุณไม่ต้องตื่นตระหนก WSW ให้คุณเลือกรายได้ที่แตกต่างกัน",
    ttt1: "จากตลาดหมี",
    t2: "เมื่อสองเดือนที่แล้ว",
    tt2: "การกระจายอำนาจการปกครองตนเอง",
    ttt2: "คำอธิบายขององค์กร",
    t3: "สามเดือนที่ผ่านมาแล้ว",
    tt3: "ระดับบนสุดของสกุลเงินการเข้ารหัสลับ",
    t4: "สำหรับ 个月前 ว่า",
    tt4: "แนวโน้มการเข้ารหัสระดับสูงสำหรับเกมและ",
    ttt4: "จักรวาลแห่งเมตา",
    rapid_real: "รวดเร็วและเรียลไทม์",
    execution: "ผู้บริหารผู้บริหาร",
    in_the_volatile: "ทุกวินาทีเป็นสิ่งสำคัญในการเปลี่ยนแปลงการเข้ารหัสลับสกุลเงินการค้าเทคโนโลยีการตอบสนองสูงของเราสามารถตอบสนองได้ทันทีในขณะที่การทำธุรกรรมขนาดใหญ่ในเวลาเดียวกัน",
    maximize: "ขยายใหญ่สุด 最大化 ว่า",
    your_passive_profits: "คุณจะได้รับผลกำไรเรื่อยๆของคุณ",
    stacke_your_fund: "ลงทุนเงินของคุณผ่านทางของเราไปที่ศูนย์กำไร Oracle หุ่นยนต์ Arbitrage โปรแกรมและการค้ากับระบบการค้าของเราโดยอัตโนมัติเพื่อเพลิดเพลินกับผลตอบแทนรายปีที่ไม่มีที่ไหนในอุตสาหกรรม",
    putting_your: "คุณจะได้รับหลักการลงทุน",
    security_first: "ความปลอดภัยของเงินต้นและผลตอบแทนการลงทุนเป็นปัจจัยแรก",
    fully_eu: "เต็มสหภาพยุโรปใบอนุญาตและกฎระเบียบที่ ArbiSmart ใช้ข้อตกลงทางเทคนิคที่เข้มงวดมากที่สุดเพื่อให้แน่ใจว่าบัญชีที่สมบูรณ์และปกป้องความเป็นส่วนตัวและความปลอดภัยของธุรกรรมอัตโนมัติทั้งหมด",
    start_now: "เริ่มได้ทันที",

    //Arbitrage
    what_is_arbitrage: "คุณคิดว่ามันเป็นสิ่งที่คุณทำ",
    arbitrage_involves: "เกี่ยวข้องกับการซื้อสินทรัพย์ในตลาดหนึ่งแล้วขายสินทรัพย์ในตลาดอื่นที่จะได้รับประโยชน์จากช่องว่างระหว่างราคา arbitrage เป็นที่ยอมรับอย่างกว้างขวางว่าเป็นโอกาสที่น่าสนใจในการลงทุนเพราะมันมีแนวโน้มที่จะให้ผลตอบแทนที่ดีต่อสุขภาพและ",
    what_is_carbitrage: "อะไรคือการเข้ารหัส arbitrage สำหรับ iPhone",
    take_adv: "เข้ารหัส Arbitrage ใช้ประโยชน์จากราคาชั่วคราวไม่มีประสิทธิภาพเหรียญพร้อมกับช่วงเวลาสั้นๆที่มีอยู่ในราคาที่แตกต่างกันเหรียญที่ซื้อในการแลกเปลี่ยนราคาต่ําสุดแล้วขายในการแลกเปลี่ยนราคาสูงสุดเพื่อสร้างผลกําไร",
    take_adv1: "WSW เป็นระบบอัตโนมัติที่สมบูรณ์ควบคุมโดยสหภาพยุโรปมันเป็น 247 สแกน 35 แลกเปลี่ยนครั้งเดียว",
    take_adv2: "ปลอดภัยและเชื่อถือได้เทคโนโลยีขั้นสูงของเราช่วยให้คุณสามารถลงทุนได้อย่างง่ายดาย",
    take_adv3: "แพลตฟอร์มนี้สามารถสร้างผลตอบแทนสูงในอุตสาหกรรมขึ้นอยู่กับขนาดของการลงทุนถึง 45 เปอร์เซ็นต์ต่อปี",
    why_become_an_arbitrage: "ทำไมถึงเป็น arbitrage สำหรับ iPhone",
    investor_with: "นักลงทุนมีนักลงทุนกับ 588th กับ",
    s1: "ศักยภาพกำไรที่ไม่มีใครเทียบได้",
    ss1: "ใช้สกุลเงินของคุณตามกฎหมายและทุนสกุลเงินที่เข้ารหัสลับและเพลิดเพลินกับโอกาสที่จะได้รับผลกำไรเรื่อยๆที่หาตัวจับยากและอัตราดอกเบี้ยชั้นนำในอุตสาหกรรม",
    s2: "เทคโนโลยีที่ทันสมัยที่สุด",
    ss2: "การใช้เทคโนโลยีขั้นสูงที่สามารถจัดการกับจำนวนมากของข้อมูลที่ความเร็วฟ้าผ่าได้ประโยชน์จากการเข้ารหัสตลาดเงินในเวลาจริง",
    s3: "ใบอนุญาตและกฎระเบียบของสหภาพยุโรป",
    ss3: "การลงทุนที่ปลอดภัยและได้รับอนุญาตแพลตฟอร์มที่สอดคล้องกับมาตรฐานที่เข้มงวดมากที่สุด",
    s4: "การเข้าถึงผ่านทางอุปกรณ์ใดๆ",
    ss4: "จัดการพอร์ตการลงทุนของคุณที่บ้านหรือเมื่อคุณออกไปและเข้าถึงบัญชีสมาร์ทของคุณจากคอมพิวเตอร์แท็บเล็ตหรือโทรศัพท์มือถือของคุณ",
    s5: "สำหรับผู้เชี่ยวชาญด้านความปลอดภัย",
    ss5: "เราได้รับบริการชั้นหนึ่งและการสนับสนุนจากทีมงานที่มีประสบการณ์ของเราในการเข้ารหัสตลาดทั่วโลก",
    s6: "สนับสนุนกฎหมายและการเข้ารหัสสกุลเงิน",
    ss6: "ใช้ความหลากหลายของสกุลเงินทางกฎหมายและการเข้ารหัสสกุลเงินที่จะใช้ประโยชน์จากการเข้ารหัสที่ทำกำไรการค้า arbitrage และกระเป๋าสตางค์",

    //สนับสนุน
    contact_us: "ติดต่อเราติดต่อเรา",
    get_in_touch_1: "ติดต่อทีมผู้เชี่ยวชาญของเราในอุตสาหกรรมและรับบริการส่วนบุคคลและเป็นมืออาชีพ",
    get_in_touch_1: "ผ่านทางอีเมลและการสนทนาแบบเรียลไทม์เจ้าหน้าที่สนับสนุนทั่วโลกของเราให้คําแนะนําทุกวัน 24hour",
    open_chat: "เปิดการสนทนากับคนอื่น",
    email: "อีเมล์อีเมลล์",
    via_email: "ติดต่อทีมงานของเราทางอีเมล์ได้ตลอดเวลา support@wallstreetwolves.com ว่า",
    live_chat: "ผู้เชี่ยวชาญด้านหมาป่าวอลล์สตรีทผู้เชี่ยวชาญระดับโลกทุกสภาพอากาศผ่านการสนทนาแบบเรียลไทม์",

    // ต่อมใต้สมอง
    status:"สถานะของรัฐ",
    arbitrage_amount: "มูลค่าของ arbitrage",
    arbi_colelcted: "อาบิคิวคอลเลกชัน",
    collect_roi: "คุณน่าจะเก็บมันไว้",
    end: "จุดจบคือจุดจบ",
    your_wallet: "กระเป๋าสตางค์ของคุณกระเป๋าสตางค์ของคุณ",
    min: "น้อยที่สุดน้อยที่สุด",
    max: "สูงสุดสูงสุดสูงสุด",
    apy: "อาจจะนะ",
    purchase_now: "การซื้อยา",
    node_purchase: "นั่นคือสิ่งที่คุณต้องการ",
    usdt_amount: "จำนวนเงินที่ถูกขโมยไป",
    usd_balance: "คิดว่าของ usdt สมดุล",
    wolf_balance: "ความสมดุลของหมาป่า",
    collect: "คอลเลกชันคอลเลกชัน",
    approve_wolf_notice: "คุณเห็นด้วยกับผมไหมครับ",
    approve_usdt_notice: "คุณเห็นด้วยกับผมหรือไม่เห็นด้วยกับผมหรือไม่เห็นด้วยกับผม",
    submit: "ส่งมาส่ง",
    enter_usdt_amount: "โปรดระบุจำนวนเงินที่ usdt สำหรับ iPhone",
    next: "คนต่อไปคนต่อไป",
    confirm_purchase: "ยืนยันการซื้อยืนยันการซื้อ",
    purchase_complete: "การซื้อสินค้าสำเร็จ",
    purchase_fail: "ล้มเหลวในการซื้อ",
    view_transaction: "มุมมองของธุรกรรมในผู้จัดการทรัพยากร",
    pacakge_1_limit: "คุณต้องอยู่ระหว่าง 100and 499 สำหรับแพคเกจ",
    pacakge_2_limit: "แพคเกจ usdt ต้องอยู่ระหว่าง 500 และ 1999",
    pacakge_3_limit: "แพคเกจ C usdt ต้องระหว่าง 200000 และ 999",

    // ส่วนท้าย
    note_1: "เว็บไซต์นี้ถูกเรียกใช้โดย Wall Street หมาป่าที่จดทะเบียนในเอเชียและได้รับอนุญาตจาก FCIS เพื่อให้บริการทางการเงินเสมือนจริง",
    arbitrage: "arbitrage",
    accounts: "บัญชีบัญชี",
    partnership: "หุ้นส่วนหุ้นส่วนหุ้นส่วน",
    support: "สนับสนุนสนับสนุน",
    company: "บริษัทบริษัท",
    careers: "อาชีพการงานอาชีพ",
    how_it_work: "มันทำงานยังไง",
    roadmap: "แผนที่เส้นทางแผนที่เส้นทาง",
    rsp: "นโยบายความเสี่ยง",
    rp: "นโยบายการคืนเงิน",
    kyc: "กลยุทธ์ Kyc-58C",
    am: "นโยบายต่อต้านการฟอกเงิน",
    tc: "ข้อตกลงและเงื่อนไข",
    pp: "นโยบายความเป็นส่วนตัว",
    quthorizations: "การอนุญาตการอนุญาต",
    blog: "บล็อกบล็อก",
    faq: "คำถามที่พบบ่อย",

    //ต่อมใต้สมอง
    eth_main_net: "สำหรับเมนูเครือข่ายหลัก",
    bsc_main_net: "เมนูหลักของเครือข่าย",
    bsc_test_net: "หนึ่งในนั้นคือการทดสอบเครือข่าย BSC",
    unknown_net: "เครือข่ายที่ไม่รู้จัก",
    ongoing: "กำลังดำเนินการอยู่ในความคืบหน้า",
    expired: "หมดอายุแล้วหมดอายุแล้ว",
    collected: "คอลเลกชันคอลเลกชัน",

    //บริษัทบัญชี
    line_1: "วอลสตรีทหมาป่าเป็นรุ่นใหม่ของดิจิตอลกองทุนนำโดยปีเตอร์จอห์นจูเลียนเจสซี่และแอนโทนี่เป็นหุ้นส่วนที่มีชื่อเสียงของบริษัทกองทุนการเงินระหว่างประเทศ",
    line_2: "บรรทัดที่สอง “วอลล์สตรีทหมาป่ามีวัตถุประสงค์เพื่อสร้างกองทุนดิจิตอลที่มีคุณสมบัติครบถ้วนทั่วโลกเป็นแพลตฟอร์มบริการทางการเงินที่ให้บริการสําหรับผู้ที่ชื่นชอบสกุลเงินดิจิตอลทั่วโลกและให้บริการทางการเงินแบบมืออาชีพมากที่สุดและมีคุณภาพสูงดิ การพัฒนาระบบนิเวศทางการเงินดิจิตอลทั้งหมด",
    line_3: "บรรทัดที่สาม ในอนาคตวอลสตรีทหมาป่าจะได้รับมากของธุรกิจที่เกี่ยวข้องกับการเงินดิจิตอลรวมถึงกองทุนสินทรัพย์ดิจิตอลเงินกู้ยืมสินทรัพย์ดิจิตอลกองทุนป้องกันความเสี่ยงสินทรัพย์ดิจิตอลสระว่ายน้ำขนาดใหญ่ของข้อมูลกองทุนสินทรัพย์ดิ นั่นมัน",
    line_4: "ผลตอบแทนการลงทุนสูงจะช่วยให้ผู้ใช้ทุกคนในโลกได้รับประโยชน์จากการเติบโตของความมั่งคั่งวอลล์สตรีทหมาป่าจะพาคุณเข้าสู่โลกการเงินที่ดีกว่า",
    
    //ปัญหาที่พบบ่อยในตับ
    q1:"Q1. อะไรคือแพลตฟอร์มสำหรับถนนกำแพงหมาป่า",
    a1:"A1: Wall Street หมาป่าเป็นรุ่นใหม่ของแพลตฟอร์มบริการทางการเงินดิจิตอลทั่วโลกและระหว่างประเทศมันจะหมุนรอบหลายธุรกิจตราสารอนุพันธ์ทางการเงินดิจิตอลรวมถึงกองทุนสินทรัพย์ดิจิตอลการลงทุนสินทรัพย์ดิจิตอลกองทุนป้องกันความเสี่ยงด้านสินทรัพย์ดิจิตอลสระว่ายน้ำขนาดใหญ่ของข้อมู",
    q2:"Q2. แล้วมันเกี่ยวข้องกับวอลล์สตรีทหมาป่า",
    a2:"A2: โดยการซื้อวอลสตรีทวูลฟ์และ USDT ภายใต้ห่วงโซ่อัจฉริยะไบแนนซ์คุณสามารถเริ่มต้นคาดการณ์หุ่นยนต์ arbitrage บริการโดยการซื้อวอลสตรีทวูลฟ์และแพลตฟอร์ม",
    q3:"Q3. วิธีที่คุณสามารถได้รับผลกำไรจากวอลล์สตรีทหมาป่า",
    a3:"A3: วอลสตรีทหมาป่าคาดการณ์ว่าหุ่นยนต์ arbitrage บริการจะดำเนินการ arbitrage ขึ้นอยู่กับแพคเกจที่ผู้ใช้เลือกทุกวัน arbitrage รายได้จะถูกตัดสินในเวลาจริงตามข้อตกลงที่ผู้ใช้ไปที่ศูนย์กระเป๋าเงินและอัตราผลตอบแทนต่อวันจาก 1.2 ถึ",
    q4:"Q4. มันปลอดภัยสำหรับวอลล์สตรีทหมาป่า",
    a4:"A4: วอลล์สตรีทหมาป่าเป็นแพลตฟอร์มบริการทางการเงินดิจิตอลทั่วโลกที่ได้รับการอนุมัติและดำเนินการโดยสถาบันการเงินในยุโรปและสหรัฐอเมริกามันถูกสร้างขึ้นโดยพันธมิตรการลงทุนกองทุนที่มีชื่อเสียงมากมายวอลล์สตรีทหมาป่ามีทีมงานที่ม",
 
    //ตัดความเสี่ยงนโยบาย
    risk_policy_management: "กลยุทธ์การบริหารจัดการความเสี่ยง",
    policy: "กลยุทธ์กลยุทธ์",
    policy_desc: "เรามุ่งมั่นที่จะจัดการอย่างเป็นระบบและมีประสิทธิภาพครอบคลุมโอกาสและผลกระทบที่อาจเกิดขึ้นผ่านการปฏิบัติที่ดีที่สุดของการบริหารความเสี่ยง",
    philosophy: "ปรัชญาปรัชญา",
    philosophy_desc: "WSW โอบกอดความเสี่ยงอัจฉริยะและตระหนักว่าความเสี่ยงสามารถมีผลในเชิงบวกและเชิงลบ",
    objectives: "เป้าหมายเป้าหมาย",
    objectives_desc: "การบริหารความเสี่ยงช่วยให้เราบรรลุเป้าหมายของเราการดำเนินงานที่มีประสิทธิภาพปกป้องบุคลากรและทรัพย์สินของเราการตัดสินใจที่ชาญฉลาดและปฏิบัติตามกฎและข้อบังคับที่ใช้บังคับ",
    business_plan: "การวางแผนธุรกิจสำหรับ iPhone",
    business_plan_desc: "การบริหารความเสี่ยงจะถูกรวมเข้ากับกระบวนการของบริษัทในทุกระดับเพื่อให้แน่ใจว่าการบริหารจัดการความเสี่ยงที่ได้รับการพิจารณาในกิจกรรมทางธุรกิจปกติ",
    application: "แอพพลิเคชั่นโปรแกรม",
    application_desc: "อินเตอร์ลิงค์ “กลยุทธ์การบริหารความเสี่ยงอย่างเป็นทางการจะถูกพัฒนาขึ้นทุกปีซึ่งจะสนับสนุนเป้าหมายของบริษัทโดยตรงและชัดเจนกลยุทธ์นี้จะถูกดำเนินการโดยการมีส่วนร่วมอย่างต่อเนื่องขององค์กรทุกระดับผ่านการวางแผนทรัพยากรที่เพียงพอกับตารางเวลาการวัดและเป้าหมายระบบของเราสอดคล้องกั การศึกษาและการฝึกอบรม",
    performance: "การแสดงการแสดงการแสดง",
    performance_desc: "ความสำเร็จของการบริหารความเสี่ยงของเราจะถูกวัดโดยผลกระทบต่อวัตถุประสงค์ของบริษัทการตรวจสอบการบริหารความเสี่ยงประจำปีการเก็บรวบรวมข้อมูลอย่างต่อเนื่องของความเสี่ยงและรูปแบบการประเมินความเสี่ยง",
    acceptance: "มาตรฐานการยอมรับ",
    acceptance_desc: "ความเสี่ยงสูงความเสี่ยงที่รุนแรงและความเสี่ยงเชิงกลยุทธ์จะถูกควบคุมโดยการกระทำของผู้บริหารระดับสูงและกำหนดกลยุทธ์การรักษาที่บันทึกไว้ในเอกสาร",
    documentation: "เอกสารเอกสารเอกสาร",
    documentation_desc: "ในแต่ละขั้นตอนของกระบวนการบริหารจัดการความเสี่ยงมีการบันทึกเอกสารที่เหมาะสมโดยเฉพาะอย่างยิ่งในการตัดสินใจและการจัดการความเสี่ยงแต่ละโครงการและทีมงานเก็บบันทึกความเสี่ยงในขณะที่ความเสี่ยงทางธุรกิจจะถูกบันทึกไว้ในฐานข้อมูลความเสี่ยงเชิ",
    responsibilities: "ความรับผิดชอบความรับผิดชอบ",
    responsibilities_desc_1: "การบริหารความเสี่ยงเป็นทักษะทางธุรกิจหลักและเป็นส่วนหนึ่งของกิจกรรมประจำวันเป็นบุคคลเราทุกคนมีบทบาทในการบริหารจัดการความเสี่ยงพนักงานทุกระดับมีความรับผิดชอบที่จะเข้าใจและใช้ระบบบริหารความเสี่ยงในสถานที่ทำงานของพวกเขา",
    responsibilities_desc_2: "หน้าที่ความรับผิดชอบ ผู้บริหารและผู้นำในระดับต่างๆมีหน้าที่รับผิดชอบในการใช้นโยบายการบริหารความเสี่ยงที่ตกลงกันแนวทางและกลยุทธ์ภายในขอบเขตของความรับผิดชอบของตนและให้แน่ใจว่าการบริหารจัดการความเสี่ยงอย่างเต็มที่บูรณาการและการพิจารณากับกระบวนการปกติของกิจกรรมในระดับต่างๆ และใช้คำว่าเสี่ยงเป็นวาระแรกของการประชุมทั้งหมด",
    responsibilities_desc_3: "ผู้จัดการแผนกรับผิดชอบในการรายงานความเสี่ยงและความก้าวหน้าในการวางแผนการบริหารความเสี่ยงคณะกรรมการที่ปรึกษาทุกเดือนรายงานกลยุทธ์หรือความเสี่ยงที่รุนแรงในเวลาที่เหมาะสมเพื่อส่งเสริมการดำเนินงานของกรอบการบริหารความเสี่ยงและให้แน่ใจว่าผู้จัดการมีทักษะที่จำเป",
    responsibilities_desc_4: "หน้าที่ความรับผิดชอบของหัวหน้าเจ้าหน้าที่บริหารความเสี่ยง รับผิดชอบในการพัฒนาประสานงานและเผยแพร่กรอบการบริหารความเสี่ยง นี้เกี่ยวข้องกับการพัฒนาโปรแกรมการฝึกอบรมและการใช้ระบบการจัดการใหม่หรือที่มีความเสี่ยงที่สามารถระบุตรวจสอบและรายงานระเบียน หัวหน้าเจ้าหน้าที่บริหารความเสี่ยงยังตรวจสอบกระบวนการบริหารจัดการความเสี่ยงการตรวจสอบและรายงานความเสี่ยงเชิงกลยุทธ์ที่สำคัญ",
}
export default th;