import $ from 'jquery';
import confirm from 'jquery-confirm';
import { toast } from 'react-toastify';
import strings from './Language';
import WalletConnectProvider from "@walletconnect/web3-provider";
export function alertMessage(message) {
    $.alert({
        theme: 'dark',
        title: strings.error,
        content: message,
        type: 'red',
        typeAnimated: true,
    });
}

export function warningMessage(message) {
    $.alert({
        theme: 'dark',
        title: "",
        content: message,
        type: 'orange',
        typeAnimated: true,
    });
}

export function tostMessage(message) {
    toast.success(message, {
        position: "top-center",
        autoClose: !0,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: 0,
    });
}
export function successMessage(message) {
    $.alert({
        theme: 'dark',
        title: strings.success,
        content: message,
        type: 'green',
        typeAnimated: true,
    });
}
export function getNetworkName(netId) {
    switch (netId) {
        case "1":
            return strings.eth_main_net;
        case "56":
            return strings.bsc_main_net;
        case "97":
            return strings.bsc_test_net;
        default:
            return strings.unknown_net;
    }
}

export const Network = {
    bsc: {
        chainId: `0x${Number(56).toString(16)}`,
        chainName: "Binance Smart Chain Mainnet",
        nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18
        },
        rpcUrls: ["https://bsc-dataseed1.binance.org/"],
        blockExplorerUrls: ["https://bscscan.com/"]
    },
}

export const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          56: 'https://bsc-dataseed1.binance.org'
        },
        chainId: 56
      }
    }
}

export function getStatusName(plan) {
    plan = parseInt(plan);
    if (plan === 1) {
        return strings.ongoing;
    }
    else if (plan === 2) {
        return strings.expired;
    }
    else if (plan === 3) {
        return strings.collected;
    }
}