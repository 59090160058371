const zh = {
    confirm: "确定",
    coming_soon: "即将来临",
    processing: "交易以提交，请等待",
    error: "错误",
    success: "成功",
    close: "关",
    language: "语言",
    language_changed: "语言已更改",
    install_metamask: "安装 Metamask",
    please_connect: "请链接钱包",
    loading: "正在处理...",
    approve:"批准",
    rejected:"已被拒绝",
    running:"执行中",

    //Header
    package: "配套",
    arbitrage: "套利",
    transaction: "交易",
    connect: "链接",
    connected: "已链接",
    connect_wallet: "链接钱包",

    //Home Page
    take_of_control: "安全便捷",
    your_finance_with:"实现您的财富增长",
    wall_street_wolves:"华尔街之狼",
    a_secure: "一个安全的全球自动化，欧盟授权的数字金融服务平台",
    generate_wallet:"预言机器人WSW",
    get_started: "开始使用",
    earn_upto: "赚取高达400%",
    interest:"回报！",
    dont_just_let_your: "不要只是让您的USDT和WOLF加密货币闲置。使用WSW语言机器人套利服务，赚取超高收益率",
    create_account:"开启服务",
    your_key:"你的钥匙",
    crypto_market:"加密市场",
    unlock_eme:"在 WSW 市场领先、获得许可和受监管的交易所安全交易，快速可靠地释放新兴机会。",
    exp_arena:"探索加密资产竞技场",
    check_our_blog:"了解区块链、DEFI和加密资产竞技游戏相关主题。阅读我们的指南，开启您的数字资产竞技之旅.",
    t1:"4 周前",
    tt1:"熊市或牛市，你都不必恐慌，wsw带给你不同的收益选择",
    ttt1:"来自熊市",
    t2:"2 个月前",
    tt2:"去中心化自治",
    ttt2:"组织 (DAO) 解释",
    t3:"3 个月前",
    tt3:"2022 年顶级 DeFi 加密货币",
    t4:"3 个月前",
    tt4:"2022 年顶级加密货币趋势：游戏和",
    ttt4:"元宇宙",
    rapid_real:"快速、实时",
    execution:"执行",
    in_the_volatile:"在动荡的加密货币交易所中，每一秒都很重要。 我们高度响应的技术可以立即做出反应，同时执行大量交易。",
    maximize:"最大化",
    your_passive_profits:"你的被动利润",
    stacke_your_fund:"通过我们的去中心化收益预言机器人套利计划投入您的资金，并使用我们的自动套利交易系统进行交易，以享受业内任何地方无法比拟的年化收益率。",
    putting_your:"投资原则",
    security_first:"投资本金与收益资金的安全是第一要素",
    fully_eu:"ArbiSmart 完全获得欧盟许可和监管，实施最严格的技术协议，以确保账户完整性，保护所有自动交易的隐私和安全。",
    start_now:"立即开始",

    //Arbitrage
    what_is_arbitrage: "什么是套利？",
    arbitrage_involves: "套利涉及在一个市场上购买资产并在另一个市场上出售以从两者之间的价格差异中获利。 套利被广泛认为提供了一个有吸引力的投资机会，因为它往往会提供健康的回报，同时使投资者面临最小的风险。",
    what_is_carbitrage: "什么是加密套利？",
    take_adv: "加密套利利用暂时的价格低效 - 硬币同时以不同价格提供的短暂时间间隔。 硬币在价格最低的交易所购买，然后在价格最高的交易所出售以产生利润。",
    take_adv1: "WSW 是一个完全受欧盟监管的自动化系统，可以 24/7 一次扫描 35 家交易所",
    take_adv2: "安全可靠，我们先进的专有技术让您安心投资d",
    take_adv3: "该平台产生行业高回报，每年高达 45%，具体取决于投资规模",
    why_become_an_arbitrage:"为什么会成为套利者",
    investor_with:"投资者与",
    s1:"无与伦比的盈利潜力",
    ss1:"享受利用您的法定和加密资本赚取无与伦比的被动利润和行业领先利率的机会",
    s2:"最先进的技术",
    ss2:"实时受益于加密货币市场，先进的技术能够以闪电般的速度处理大量数据",
    s3:"欧盟许可和监管",
    ss3:"安心投资，拥有极其安全的许可平台，符合最严格的监管标准",
    s4:"通过任何设备访问",
    ss4:"在家或在旅途中管理您的投资组合，随时通过您的计算机、平板电脑或手机访问您的 ArbiSmart 帐户",
    s5:"专家 24/7 全天候协助",
    ss5:"全天候从我们经验丰富的全球加密市场专业团队那里获得一流的服务和支持",
    s6:"支持法定货币和加密货币",
    ss6:"使用多种法定货币和加密货币，利用利润丰厚的加密交换、套利和钱包机会",

    //Support
    contact_us:"联系我们",
    get_in_touch_1:"与我们的行业专家专家团队取得联系，获得个性化、专业的",
    get_in_touch_1:"我们的全球支持人员通过电子邮件和实时聊天提供全天 24 小时的指导。",
    open_chat:"打开聊天",
    email:"电子邮箱",
    via_email:"随时通过电子邮件联系我们的团队：support@wallstreetwolves.com",
    live_chat:"华尔街狼队专家，全球专业人士，24/7，通过实时聊天",

    // Package
    status:"状态",
    arbitrage_amount:"套利金额",
    arbi_colelcted:"可套利金额",
    collect_roi:"收集投资回报率",
    end:"结束",
    your_wallet:"你的钱包",
    min:"最少",
    max:"最多",
    apy:"年化收益",
    purchase_now:"购买",
    node_purchase:"套餐购买",
    usdt_amount:"USDT 量",
    usd_balance:"USDT 余额",
    wolf_balance:"WOLF 余额",
    collect:"收集",
    approve_wolf_notice:" 需要 WOLF 的数量作为管理费。 你赞成吗？",
    approve_usdt_notice:"Wolves Of Wall Street 将从您的钱包中扣除 {0} 的 USDT。 你同意吗？ ",
    submit:"提交",
    enter_usdt_amount:"请输入USDT金额",
    next:"下一个",
    confirm_purchase:"确认购买？",
    purchase_complete:"购买成功",
    purchase_fail:"购买失败",
    view_transaction:"在浏览器中查看事务",
    pacakge_1_limit:"套餐-A USDT必须在100到499之间",
    pacakge_2_limit:"套餐-B USDT必须在500到1999之间",
    pacakge_3_limit:"套餐-C USDT必须在2000到9999之间",

    // Footer
    note_1:"本网站由在亚洲注册并获得 FCIS 授权提供虚拟货币服务的公司 Wall Street Wolves 运营，注册的",
    arbitrage:"套利",
    accounts:"账户",
    partnership:"合伙",
    support:"支持",
    company:"公司",
    careers:"职业",
    how_it_work:"这个怎么运作",
    roadmap:"路线图",
    rsp:"风险政策",
    rp:"退款政策",
    kyc:"KYC 政策",
    am:"反洗钱政策",
    tc:"条款和条件",
    pp:"隐私政策",
    quthorizations:"授权",
    blog:"博客",
    faq:"常问问题",
    how_to_operate:"操作指引?",

    //Util
    eth_main_net: "以太坊主网",
    bsc_main_net: "币安链主网",
    bsc_test_net: "币安链测试网",
    unknown_net: "Unknown Net",
    ongoing: "进行中",
    expired: "已到期",
    collected: "已领取",

    //Company
    line_1:"华尔街狼是由国际金融基金公司知名合伙人Peter、John、Julian Jesse、Anthony联合领投的新一代全数字基金。 一个新的数字帝国即将崛起。",
    line_2:"华尔街狼的宗旨是打造全球全属性数字基金。 金融服务平台，服务全球数字货币爱好者，为他们提供最专业、最优质的数字金融服务，华尔街狼数字基金将率先推出100%去中心化交易预测交易机器人盈利服务体系，发行生态币 用于发展整体数字金融生态系统。",
    line_3:"未来，华尔街狼将围绕数字金融衍生出诸多业务，包括数字资产投资基金、数字资产借贷、数字资产对冲基金、大数据基金池、数字资产旅游地产元界基金池等多项业务，华尔街狼 拥有最强的技术团队、最佳的基金合作伙伴和庞大的全球关系网络。",
    line_4:"以合理的金融服务业务闭环，高回报的投资必将成就全球每一位用户。 实现财富增长。 华尔街狼带您进入更美好的金融世界。",

    //FAQ
    q1:"Q1. 华尔街狼队是什么平台?",
    a1:"A1: 华尔街狼是一个全球化、国际化的新一代数字金融服务平台。 它将围绕数字金融衍生出众多业务，包括数字资产投资基金、数字资产借贷、数字资产对冲基金、大数据基金池、数字资产旅游地产等。 元界资金池等众多业务。",
    q2:"Q2. 华尔街狼队如何参与？",
    a2:"A2: 通过币安智能链下的必应去中心化交易所购买华尔街狼平台代币WOLF和USDT，进入华尔街狼平台选择适合自己的服务包，即可开启预测机器人套利服务。",
    q3:"Q3. 如何在华尔街狼获利？",
    a3:"A3: 华尔街狼预测，机器人套利服务将根据用户选择的服务包进行套利，每日套利收益将根据协议实时结算在用户的去中心化钱包中。 每日回报率从 1.2% 到 4% 不等。",
    q4:"Q4. 华尔街狼安全吗？",
    a4:"A4: 华尔街狼是由欧美金融机构批准运营的全球数字金融服务平台。 由众多知名基金投资合伙人共同创立。 华尔街狼队拥有最强的技术团队、最佳的基金合作伙伴和大量的全球基金。 关系网络安全可靠。",

    //Risk Policy
    risk_policy_management:"风险管理政策",
    policy:"政策",
    policy_desc:"我们致力于通过实现风险管理的最佳实践，采用系统和全面的方法来有效管理潜在机会和不利影响。",
    philosophy:"哲学",
    philosophy_desc:"WSW 接受明智的冒险，并认识到风险可能会产生积极和消极的后果。",
    objectives:"目标",
    objectives_desc:"风险管理帮助我们实现目标、有效和高效地运营、保护我们的人员和资产、做出明智的决定并遵守适用的法律和法规。",
    business_plan:"商业计划",
    business_plan_desc:"风险管理将与各级企业流程充分整合，确保在正常的业务活动过程中予以考虑。",
    application:"Application",
    application_desc:"每年将制定正式的风险管理战略，直接和明显地支持公司目标。 它将通过具有可衡量的时间表和目标的资源充足的计划在组织所有级别的持续参与下实施。 我们的系统符合 ISO31000:2009 风险管理标准，并得到持续的教育和培训计划的支持。",
    performance:"表现",
    performance_desc:"我们风险管理的成功将通过其对我们公司目标的影响、审计、年度风险管理审查、风险数据的持续收集和风险模型的评估来衡量。",
    acceptance:"验收标准",
    acceptance_desc:"高风险、极端风险和/或战略风险通过高级管理行动控制，并分配记录的处理策略。 中等风险被赋予特定的管理责任，而低风险则通过常规程序进行管理。",
    documentation:"文档",
    documentation_desc:"风险管理过程的每个阶段都有适当的记录，特别是决策和风险处理。 单个项目和小组维护风险登记册，而企业风险记录在战略风险数据库中。",
    responsibilities:"责任",
    responsibilities_desc_1:"风险管理是一项核心业务技能，也是日常活动不可或缺的一部分。 作为个人，我们都在管理风险方面发挥自己的作用，各级员工都有责任在工作场所了解和实施风险管理系统。",
    responsibilities_desc_2:"各级管理人员和领导者有责任在其职责范围内应用商定的风险管理政策、指导方针和战略，并应确保风险管理与各级活动的正常过程充分结合并加以考虑。 可见的承诺需要积极参与风险管理流程、有效的资源分配以及将“风险”作为所有会议的首要议程项目。",
    responsibilities_desc_3:"部门经理负责每月向风险管理指导委员会报告风险和处置计划的进展情况，及时报告战略或极端风险，推动风险管理框架的实施，并确保经理配备必要的 技能、指导和工具",
    responsibilities_desc_4:"首席风险官 (CRO) 负责风险管理框架的制定、协调和颁布。 这包括制定培训计划和实施能够识别、监控和报告已记录的新风险或新风险的管理系统。 CRO 还负责审查风险管理流程、监控和报告关键战略风险。",
};
export default zh;