const vn = {
    
    confirm: "Xác nhận",
    coming_soon: "sắp tới rồi",
    processing: "Giao dịch đã gửi",
    error: "Lỗi",
    success: "thành công",
    close: "đóng lại",
    language: "Ngôn ngữ",
    language_changed: "Ngôn ngữ thay đổi",
    install_metamask: "cài đặt Metamặt nạ",
    please_connect: "Làm ơn kết nối ví",
    loading: "xử lý",
    approve: "phê chuẩn",
    rejected: "bị từ chối",
    running: "Chạy",

    //Language
    package: "gói hàng",
    arbitrage: "tùy chọn",
    transaction: "Giao dịch",
    connect: "kết nối",
    connected: "kết nối",
    connect_wallet: "kết nối u ví",

   //Trang nhà
   take_of_control: "An toàn và tiện lợi",
   your_finance_with: "Hãy lớn mạnh lên",
   wall_street_wolves: "Những người sói hãy tập trung vào thành phố Wall.",
   a_secure: "Một nền tảng dịch vụ tài chính ảo an toàn toàn toàn trên toàn cầu",
   generate_wallet: "hãy tạo ra dạng ví",
   get_started: "bắt đầu sử dụng",
   earn_upto: "kiếm được bao nhiêu tiền trên",
   interest: "Return!",
   dont_just_let_your: "Đừng chỉ để cho thẻ thanh to án dạng mã hóa USDT và WOLF ngồi yên t ại chỗ. Hãy dùng dịch vụ tùy chọn robot dạng WSW để có được siêu suất cao",
   create_account: "Khởi động dịch vụ",
   your_key: "chìa khóa đây.",
   crypto_market: "chợ mật mã",
   unlock_eme: "mở khóa còng bảo mật giao dịch trên thị trường của WSW, bằng giấy phép và hỗn hợp để giải phóng những cơ hội mới ra một cách nhanh chóng và đáng tin cậy.",
   exp_arena: "Khám phá các hoạt động bí mật",
   check_our_blog: "Tìm hiểu về các chủ đề liên quan đến chuỗi chắn, DEPI, và giải mã tài s ản cạnh tranh game. Đọc hướng dẫn để bắt đầu cuộc hành trình của tài sản số của bạn. (Chạy cạnh tranh.)",
   t1:"4 tuần trước khi chết",
   tt1:"Chợ gấu hay chợ bò, em không cần phải hoảng loạn, t ự do mua cho em những lối đi thu nhập khác nhau",
   ttt1:"Ảnh từ chợ gấu",
   t2:"2 Month Ago",
   tt2:"Ảnh",
   ttt2:"Ảnh được giải thích...",
   t3:"3 Month Ago",
   tt3:"Top DeFi Cryptocurrencies in 2022",
   t4: "个月前 ",
   t4:"3 个月前",
   tt4:"Top Crypto Trends for 2022: Gaming and",
   ttt4:"Metaverse",
   rapid_real: "Cám ơn đã nhắc nhở",
   execution: "Cũng được",
   in_the_volatile: "nhanh hành động nhanh, thực hành hành hình Trong các giao dịch dịch biến đổi, mỗi giây đều được tính toán. Công nghệ có phúc đáp cao có thể phản ứng ngay lập tức trong khi thực hiện giao dịch lớn cùng lúc.",
   maximize: "最大化 ",
   your_passive_profits: "lợi nhuận thụ động;lợi nhuận thụ động",
   stacke_your_fund: "đầu tư tiền của bạn qua chương trình phân loại của chúng tôi và thương lượng với hệ thống thương lượng ngẫu nhiên của chúng tôi để có được một tỉ lệ trả thù không ai sánh bằng bất cứ nơi nào trong ngành này.",
   putting_your: "nguyên tắc đầu tư",
   security_first:"an ninh dạng đầu tiên:an ninh của vốn đầu tư và quỹ thu nhập là nhân tố đầu tiên",
   fully_eu:"Một công ty được cấp phép và điều chỉnh đầy đủ, ArbiSmart thực hiện các giao thức công nghệ khắt khe nhất để đảm bảo tính mạng và an ninh của tất cả các giao dịch tự động",
   start_now: "hãy bắt đầu Bây giờ",

   //bên bộ
   what_is_arbitrage:"Kiểu gì tùy thích, tùy chọn lựa chọn?",
   arbitrage_involves:"Kiểu tập đoàn này có liên quan đến Chứng mất tập đoàn bao gồm việc mua một tài sản trên một thị trường và bán nó trên một thị trường khác để kiếm lợi từ sự khác biệt giá giữa hai trường. Chứng khoán được xem là một cơ hội đầu tư hấp dẫn vì nó có xu hướng cung cấp lợi nhuận lành mạnh, trong khi cho nhà đầu tư mạo hiểm tối thiểu.",
   what_is_carbitrage:"Cái gì? dữ liệu mật mã?",
   take_adv:"sử dụng giá rẻ trong khoảng thời gian ngắn, khoảng thời gian ngẫu nhiên mà đồng xu có sẵn ở mức giá khác nhau. Đồng xu được mua ở chỗ giá thấp nhất, sau đó bán tại nơi giá cao nhất để kiếm lợi nhuận.",
   take_adv1: "WSW là một hệ thống tự động thuộc toàn cõi Châu, và đã quét hết ba mươi năm trao đổi cùng một lúc, 24/7",
   take_adv2: "hãy bảo đảm an to àn và tin cậy, công nghệ độc quyền cao cấp của chúng tôi cho phép anh đầu tư yên ổn",
   take_adv3: "The platform to ra kinh doanh high results, of up đến 45='a year depending on the amount of the investment",
   why_become_an_arbitrage: "tại sao anh lại trở thành'u tùy chọn Tại sao phải làm theo búa",
   investor_with: "nhà đầu tư với",
   s1: "tiềm năng lợi nhuận ngang ngửa",
   ss1: "Hãy tận hưởng cơ hội kiếm lợi nhuận thụ động vô song và lãi suất hàng đầu của ngành công nghiệp trên vốn thất bại và mã hóa",
   s2: "công nghệ cao cấp",
   ss2: "Hãy tận dụng thị trường tiền tệ mật trong thời gian thực, với công nghệ tiên tiến có khả năng xử lý hàng loạt dữ liệu với tốc độ sấm sét",
   s3:"EU licensed and regulated",
   ss3: "Đầu tư cho an toàn, với một nền tảng được cấp phép đặc biệt an toàn, có đầy đủ tiêu chuẩn quy định",
   s4:"Access via any device",
   ss4: "Hãy quản lý danh mục của bạn từ nhà hoặc trên đường đi, truy cập vào tài khoản Arbor Smart, bất cứ lúc nào, thông qua máy tính, máy tính hay di động của bạn",
   s5: "sự hỗ trợ 24/7",
   ss5: "Nhận dịch vụ hạng nhất và hỗ trợ hàng ngày từ những chuyên gia ngũ các loại mật mã có kinh nghiệm",
   s6: "Hỗ trợ ảo và mật mã",
   ss6: "Hãy tận dụng cơ hội trao đổi mã hóa, phân xử và ví và sử dụng các loại ảo và mã hóa",

    //Hỗ trợ
    contact_us: "liên lạc với chúng tôi",
    get_in_touch_1: "hãy hỏi u touch Hãy liên lạc với chuyên gia của chúng tôi về lĩnh vực chuyên nghiệp và nhận lấy cá nhân, chuyên nghiệp",
    get_in_touch_1: "Hướng dẫn 24h mỗi ngày, từ các nhân viên hỗ trợ toàn cầu, qua e-mail và chat trực tiếp.",
    open_chat: "mở chat",
    email:"e-mail",
    via_email: "Liên lạc đội chúng tôi qua e-mail, bất cứ lúc nào, support@wallstreetwolves.com ",
    live_chat:"Chuyên gia Sói phố Wall, chuyên gia toàn cầu sẵn sàng, 24/7, thông qua chat trực tiếp]",
    
    //gia nhập
    status: "trạng thái",
    arbitrage_amount: "giá trị của tập đoàn",
    arbi_colelcted:"Arbi Collecable",
    collect_roi: "hãy tập hợp ROI.",
    end: "kết thúc",
    your_wallet: "Ví của anh",
    min: "phút",
    max: "Max.",
    apy: "không hẳn.",
    purchase_now: "Purchase",
    node_purchase:"Gói hàng",
    usdt_amount: "Giá trị giọt lệ",
    usd_balance: "Giá trị hơi cao",
    wolf_balance: "so sánh giữa sói và sói",
    collect: "sưu tập tập.",
    approve_wolf_notice: "lượng WOLF cần làm phí quản lý. Bạn chấp nhận không?",
    approve_usdt_notice: "phê chuẩn dùng để chú ý rằng Những con sói của Phố Wall sẽ rút khỏi. (0) số lượng USDT trong ví của bạn. Có đồng ý không?",
    submit: "nộp",
    enter_usdt_amount: "Nhập giá trị USDT",
    next: "tiếp theo",
    confirm_purchase: "Xác nhận mua",
    purchase_complete: "Thành công mua",
    purchase_fail:"Tháng mua",
    view_transaction: "Xem chuyển giao dịch trong nhà thám hiểm.",
    pacakge_1_limit: "đi bao nhiêu lần chưa xác định giá trị của gói du lịch",
    pacakge_2_limit: "đi bao lâu chưa sử dụng virus Comment",
    pacakge_3_limit: "đi bao xa chưa xác định",

    //Language
    note_1: "This website is operated by Wall Street Wolves, a company that is registered in the Asia và appreciated by the FCIS to provide ảo transaction services, with the recorded",
    arbitrage: "Kiểu tập đoàn",
    accounts: "tài khoản",
    partnership: "đối tác",
    support: "hỗ trợ",
    company: "công ty",
    careers: "sự nghiệp",
    how_it_work: "Làm sao nó hoạt động",
    roadmap: "bản đồ",
    rsp: "Chính sách rủi ro",
    rp: "Từ chối Chính sách",
    kyc:"đã đúng chưa",
    quthorizations: "Chính sách AML",
    tc:"Ly hn",
    am:"AML Policy",
    tc:"Terms and Conditions",
    pp:"Privacy Policy",
    quthorizations:"Authorizations",
    blog:"Blog",
    faq:"FAQ",
    how_to_operate:"How to operate?",

    //Comment
    eth_main_net: "Elizabeth Main Net",
    bsc_main_net: "mạng chính BSC",
    bsc_test_net: "mạng kiểm tra BSC",
    unknown_net: "vô danh",
    ongoing: "Tiếp tục",
    expired: "hết hạn",
    collected: "sưu tập",

    //Language
    line_1: "Phố Wall Wolves là một thế hệ mới của to àn bộ số tài trợ số bị cầm đầu bởi Peter, John, Julian Jesse, và Anthony, những đối tác nổi tiếng của các công ty tài chính quốc tế.",
    line_2: "Mục đích của Phố Wall Wolves là tạo ra một quỹ số to àn năng toàn cầu. một nền tảng dịch vụ tài chính, phục vụ những người đam mê tiền tệ số toàn cầu và cung cấp cho họ những dịch vụ tài chính kỹ thuật số cao chuyên nghiệp và chất lượng cao, quỹ số của Phố Wall Wolves lần đầu sẽ phát động một hệ thống dự đoán hoạt động kinh doanh robot, và phát hành đồng xu. dùng để phát triển hệ thống sinh thái tài chính số chung.",
    line_3: "Trong tương lai, Phố Wall Sói sẽ thu thập nhiều doanh nghiệp quanh tài chính kỹ thuật số, bao gồm quỹ đầu tư tài sản kỹ thuật số, tín dụng kỹ thuật số, quỹ đầu tư, các hồ bơi tài liệu lớn, và du lịch tài sản số các quỹ bất động sản siêu phàm và nhiều doanh nghiệp khác, Phố Wall Sói có đội kỹ thuật mạnh nhất, các đối tác đầu quỹ tốt nhất và một mạng lưới quan hệ rộng lớn, Tàu",
    line_4: "Với hợp lý phục vụ tài chính kinh doanh đóng vòng, điểm đầu tư cao chắc chắn sẽ làm cho mỗi người sử dụng trên thế giới. Giá trị tăng trưởng của sự giàu có. Phố Wall sói đưa bạn đến một thế giới tài chính tốt hơn.",
    
    //Language
    q1: "Q1. nền tảng nào là Sói Phố Wall?",
    a1: "A1: Wall Street Wolves là một nền tảng dịch vụ tài chính cá nhân toàn cầu và quốc tế quốc tế mới tạo ra. Nó sẽ thu hồi nhiều doanh nghiệp quanh tài chính số, bao gồm số các quỹ đầu tư sản sản, tín dụng kỹ thuật số, các hồ bơi khủng bố dữ liệu lớn, và du hành động ảo.",
    q2:"Q2 Những con sói Wall tham gia thế nào?",
    a2:"A2: Mua nền tảng Sói Phố Wall để thẻ WOLF và USDT qua trung tâm giao dịch Bing dưới kênh hiệu Binance Smart Chain và enter the Wall Street Wolves platform to choice gói dịch vụ thích hợp với bạn, và bạn có thể khởi động dịch vụ phân hủy dự đoán của robot.",
    q3: "Q3.Những con sói Wall có lợi nhuận?",
    a3: "A3: Wall Street Wolves dự đoán rằng các dịch vụ trọng tài robot s ẽ phân phối các giao ước dựa trên gói dịch vụ được chọn bởi người dùng, và thu nhập hàng ngày của các tập đoàn sẽ được giải quyết trong thời gian thực dựa trên hợp đồng trong ví người dùng. « 817",
    q4: "Q4.Sói ở Phố Wall an toàn không?",
    a4: "A4: Wall Street Wolves là một nền tảng tài chính số trên toàn cầu được chấp nhận và điều hành bởi các tổ chức tài chính Châu Âu và Mỹ. Nó được thành lập bởi nhiều đối tác đầu tư quỹ nổi tiếng. Wall Street Wolves có đội kỹ thuật mạnh nhất, các đối tác quỹ giỏi nhất và một số nguồn tài chính quy mô lớn toàn cầu.",

    //Chính sách rủi ro
    risk_policy_management: "Chính sách Quản lý rủi ro",
    policy: "chính sách",
    policy_desc: "« Chúng tôi sẵn sàng dấn thân vào một phương pháp có hệ thống và to àn diện về việc quản lý khả năng và tác động xấu bằng cách đạt được hành động tốt nhất trong việc quản lý rủi ro.",
    philosophy: "Triết học",
    philosophy_desc: "« WSW bao gồm việc đón nhận mạo hiểm thông minh và công nhận rằng rủi ro có thể có cả những hậu quả tích cực và tiêu cực.",
    objectives: "mục tiêu",
    objectives_desc: "« Sự quản lý mạo hiểm giúp chúng ta đạt được mục tiêu, hoạt động hiệu quả và hiệu quả, bảo vệ nhân dân và tài sản, đưa ra quyết định dựa trên thông tin và tuân thủ pháp luật.",
    business_plan: "Kế hoạch kinh doanh",
    business_plan_desc:"Quản lý rủi ro sẽ được tổng hợp đầy đủ với các thủ tục công ty tại mọi cấp để đảm bảo nó được cân nhắc trong hoạt động kinh doanh bình thường.",
    application: "Ứng dụng",
    application_desc: "Một chiến lược Quản lý rủi ro chính thức sẽ được phát triển mỗi năm, hỗ trợ trực tiếp và hiển nhiên cho các mục tiêu công ty. Nó sẽ được thực hiện với sự tham gia lâu dài của mọi cấp độ của tổ chức thông qua các kế hoạch có nguồn đầy đủ với thời gian và mục tiêu xác định. Hệ thống của chúng tôi được phù hợp với ISO31000:2009 Name luyện tập.",
    performance: "suất",
    performance_desc:"Sự thành công của sự quản lý rủi ro sẽ được đánh giá bằng tác động của nó lên các mục tiêu công ty, qua các kiểm tra, kiểm tra ban đầu về quản lý rủi ro, các dữ liệu về rủi ro đang được thu thập và đánh giá các mô hình rủi ro.",
    acceptance: "Chấp nhận",
    acceptance_desc: "Cao, cực đoan, và/ hay những rủi ro chiến lược được kiểm soát qua hành động quản lý cao cấp với các chiến lược điều trị được xác định. Giới hạn rủi ro trung bình được giao nhiệm vụ quản lý đặc biệt, trong khi những rủi ro thấp được quản lý thông qua thủ tục.",
    documentation: "tài liệu",
    documentation_desc: "Mỗi giai đoạn của quá trình quản lý rủi ro được ghi nhận đầy đủ, đặc biệt là các quyết định và phương pháp điều trị rủi ro.",
    responsibilities: "trách nhiệm",
    responsibilities_desc_1: "trách nhiệm cao 5:Sự quản lý rủi ro là một kỹ năng cơ bản và là một phần của hoạt động hàng ngày. Là cá nhân, chúng tôi đều có vai trò trong việc quản lý rủi ro, và nhân viên ở mọi cấp độ chịu trách nhiệm về hiểu biết và thực hiện hệ thống quản lý rủi ro tại nơi làm việc của họ.",
    responsibilities_desc_1: "Quản lý và lãnh đạo ở mọi cấp độ đều chịu trách nhiệm thực hiện chính sách, hướng dẫn và các chiến lược đồng ý trong lĩnh vực của họ và được trông đợi để đảm bảo quản lý rủi ro được hoàn to àn hòa nhập và cân nhắc trong cuộc hành động bình thường ở mọi cấp độ. và ghi tên'82-16;i hạn hạn; mục đầu tiên trong mọi cuộc họp",
    responsibilities_desc_1: "trách nhiệm phải được kiểm soát từng tháng, báo cáo về những rủi ro chiến lược hay cực hạn, thúc đẩy hệ thống quản lý rủi ro, và đảm bảo quản lý được trang bị đầy đủ kỹ năng, hướng dẫn và công cụ cần thiết",
    responsibilities_desc_1: "trách nhiệm phải giải quyết đề án 4: chịu trách nhiệm phát triển, phối hợp và phát triển bộ khung Quản lý rủi ro. Việc này bao gồm việc phát triển các chương trình đào tạo và hệ thống quản lý có khả năng xác định, theo dõi và ghi nhận các rủi ro mới hoặc mới. Hội đồng quản trị (CRO) cũng chịu trách nhiệm cho việc xem xét tiến trình quản lý rủi ro, giám sát và báo cáo những rủi ro chiến lược quan trọng.",
}
export default vn;