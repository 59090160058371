import React from "react";
import Footer from '../components/Footer';
import Header from "../components/Header";
import './../App.css';
import Web3 from "web3";
import {NODE_URL } from './../config/node';
import strings from "../components/Language";
class Arbitrage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
        }
    }

    async componentDidMount() {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        if (window.ethereum) {
            const web3 = new Web3(window.ethereum || NODE_URL);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                
            }
        }
    }

    render() {
        return (
            <>
                <Header />
                <section className="main">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col order-md-2 center">
                                <figure>			
                                    <img src="assets/images/art_05.png" alt=""/>
                                </figure>
                            </div>
                            <div className="col order-md-1">
                                <h1>{strings.what_is_arbitrage}</h1>
                                <p>{strings.arbitrage_involves}</p>
                                <a href="#" className="btn btn-dark mt-3">{strings.get_started}</a>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col center">
                                <figure>			
                                    <img src="assets/images/art_06.png" alt=""/>
                                </figure>
                            </div>
                            <div className="col order-md-1">
                                <h1>{strings.what_is_carbitrage}</h1>
                                <p>{strings.take_adv}</p>
                                <ul className="lists">
                                    <li>{strings.take_adv1}</li>
                                    <li>{strings.take_adv2}</li>
                                    <li>{strings.take_adv3}</li>
                                </ul>
                                <a href="#" className="btn btn-dark mt-3">{strings.create_account}</a>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="why">
                        <div className="row">
                            <div className="col-12 mb-5 center">
                                <h2>{strings.why_become_an_arbitrage}<br/>
                                {strings.investor_with} <span>{strings.wall_street_wolves}</span></h2>
                            </div>
                            <div className="col col-md-6">
                                <div className="row">
                                    <div className="col-md-3 center">
                                        <figure>
                                            <img src="assets/images/icn_01.svg" alt=""/>
                                        </figure>
                                    </div>
                                    <div className="col">
                                        <p><strong>{strings.s1}</strong><br/>
                                        {strings.ss1}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="row">
                                    <div className="col-md-3 center">
                                        <figure>
                                            <img src="assets/images/icn_02.svg" alt=""/>
                                        </figure>
                                    </div>
                                    <div className="col">
                                        <p><strong>{strings.s2}</strong><br/>
                                        {strings.ss2}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="row">
                                    <div className="col-md-3 center">
                                        <figure>
                                            <img src="assets/images/icn_03.svg" alt=""/>
                                        </figure>
                                    </div>
                                    <div className="col">
                                        <p><strong>{strings.s3}</strong><br/>
                                        {strings.ss3}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="row">
                                    <div className="col-md-3 center">
                                        <figure>
                                            <img src="assets/images/icn_04.svg" alt=""/>
                                        </figure>
                                    </div>
                                    <div className="col">
                                        <p><strong>{strings.s4}</strong><br/>
                                        {strings.ss4}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="row">
                                    <div className="col-md-3 center">
                                        <figure>
                                            <img src="assets/images/icn_05.svg" alt=""/>
                                        </figure>
                                    </div>
                                    <div className="col">
                                        <p><strong>{strings.s5}</strong><br/>
                                        {strings.ss5}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="row">
                                    <div className="col-md-3 center">
                                        <figure>
                                            <img src="assets/images/icn_06.svg" alt=""/>
                                        </figure>
                                    </div>
                                    <div className="col">
                                        <p><strong>{strings.s6}</strong><br/>
                                        {strings.ss6}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 center">
                                <a href="#" className="btn btn-dark">{strings.get_started}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
export default Arbitrage;
