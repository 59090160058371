import React from "react";
import Footer from '../components/Footer';
import Header from "../components/Header";
import './../App.css';
import Web3 from "web3";
import {NODE_URL } from './../config/node';
import strings from "../components/Language";

class Support extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }

    async componentDidMount() {
        if (window.ethereum) {
            const web3 = new Web3(window.ethereum || NODE_URL);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                
            }
        }
    }

    render() {
        return (
            <>
                <Header />
                <section className="main pb-0">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col center mb-5">
                                <h1>{strings.contact_us}</h1>
                                <p>{strings.get_in_touch_1}<br/>{strings.get_in_touch_2}</p>
                                <a href="#" className="btn btn-open mt-3">{strings.open_chat}</a>
                                <br/><br/>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div className="support">
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col-md-3 center">
                                        <img src="assets/images/mail.png" alt=""/>
                                    </div>
                                    <div className="col">
                                        <p><strong>{strings.email}</strong><br/>
                                        {strings.via_email}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col-md-3 center">
                                        <img src="assets/images/icn_05.svg" alt=""/>
                                    </div>
                                    <div className="col">
                                        <p><strong>24/7</strong><br/>
                                        {strings.live_chat}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}
export default Support;
