import React from "react";
import './../App.css';
import strings from "../components/Language";
import Web3 from 'web3'
import detectEthereumProvider from '@metamask/detect-provider';
import { getNetworkName, Network,providerOptions } from './../components/Util';
import { alertMessage } from './../components/Util';
import Web3Modal from "web3modal";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
            contract: null,
            account: null,
            is_connected: false,
            net: "",
            auth_token: "",
        }
        
    }
    async componentDidMount() {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        if (window.ethereum) {
            const web3 = new Web3(window.ethereum || Network.bsc.rpcUrls);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ is_connected: true })
                this.setState({ account: accounts[0] })
                this.setState({ net: getNetworkName(window.ethereum.networkVersion) });
            }
            const networkId = await web3.eth.net.getId()
            if (networkId !== 56) {
                await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        {
                            ...Network["bsc"]
                        }
                    ]
                });

                setTimeout(function(){
                    window.location.reload();
                },3000)
                return
            }
            
            const provider = await detectEthereumProvider();
            if (provider) {
                window.ethereum.on("accountsChanged", async accounts => {
                    if (accounts.length > 0) {
                        let { web3 } = window
                        web3 = new Web3(web3.currentProvider);
                        let accounts = await web3.eth.getAccounts();
                        this.setState({ account: accounts[0] })
                        setTimeout(function () {
                            window.location.reload();
                        }, 5000)
                    }
                    else {
                        this.setState({ is_connected: false })
                    }
                });
            }
        }
        else {

        }
    }

    async doConnect() {
        const web3Modal = new Web3Modal({
            network: "mainnet",
            providerOptions,
            theme: "dark"
        });
        const provider = await web3Modal.connect();
        if (provider) {
            try {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                this.setState({ is_connected: true })
            } catch (error) {
                if (error.code === 4001) {
                    alertMessage(error.message);
                }
                else {
                    alertMessage(strings.install_metamask);
                }
            }
        } else {
            alertMessage(strings.install_metamask);
        }
    }

    /**
     * Change Language
     * @param {string} obj 
     */
    async changeLanguage(obj) {
        if (obj !== "") {
            strings.setLanguage(obj);
            await localStorage.setItem("language", obj);
            this.setState({ lang: strings[obj] });
            window.location.reload();
        }
        else {
            strings.setLanguage('en');
            await localStorage.setItem("language", 'en');
            this.setState({ lang: strings.en });
            window.location.reload();
        }
    }

    render() {
        return (
            <>
                <nav className="navbar fixed-top navbar-expand-lg" id="header">
                    <div className="container">
                        <a className="navbar-brand" href="/">
                            <img src="assets/images/logo.png" alt="Wall Street Wolves" className="logo"/>
                            <img src="assets/images/logo_01.png" alt="Wall Street Wolves" className="scrollLogo"/>
                        </a>
                        <div className="dropdown user-sm">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="assets/images/globe-white.svg" alt="" className="user"/>
                                <img src="assets/images/globe.svg" alt="" className="userblk"/>
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" onClick={()=>this.changeLanguage("en")}>English</a></li>
                                <li><a className="dropdown-item" onClick={()=>this.changeLanguage("zh")}>简体中文</a></li>
                                <li><a className="dropdown-item" onClick={()=>this.changeLanguage("th")}>ไทย</a></li>
                                <li><a className="dropdown-item" onClick={()=>this.changeLanguage("jp")}>日本語</a></li>
                                <li><a className="dropdown-item" onClick={()=>this.changeLanguage("rs")}>Русский</a></li>
                                <li><a className="dropdown-item" onClick={()=>this.changeLanguage("vn")}>Tiếng Việt</a></li>
                                <li><a className="dropdown-item" onClick={()=>this.changeLanguage("kr")}>한국어</a></li>
                            </ul>
                        </div>
                        <button className="navbar-toggler hm-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <a className="nav-link wlink" href="/arbitrage">{strings.arbitrage}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link wlink" href="/package">{strings.package}</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link wlink" href="/support">{strings.support}</a>
                                </li>
                            </ul>
                            <div className="row ms-3">
                                <div className="col-auto dropdown d-block">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="assets/images/globe-white.svg" alt="" className="user"/>
                                        <img src="assets/images/globe.svg" alt="" className="userblk"/>
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><a className="dropdown-item" onClick={()=>this.changeLanguage("en")}>English</a></li>
                                        <li><a className="dropdown-item" onClick={()=>this.changeLanguage("zh")}>简体中文</a></li>
                                        <li><a className="dropdown-item" onClick={()=>this.changeLanguage("th")}>ไทย</a></li>
                                        <li><a className="dropdown-item" onClick={()=>this.changeLanguage("jp")}>日本語</a></li>
                                        <li><a className="dropdown-item" onClick={()=>this.changeLanguage("rs")}>Русский</a></li>
                                        <li><a className="dropdown-item" onClick={()=>this.changeLanguage("vn")}>Tiếng Việt</a></li>
                                        <li><a className="dropdown-item" onClick={()=>this.changeLanguage("kr")}>한국어</a></li>
                                    </ul>
                                </div>
                                <div className="col-auto">
                                {
                                    this.state.is_connected ?
                                        <button className="btn btn-dark btn-wallet">{this.state.net}</button> :
                                        <button className="btn btn-dark btn-wallet" onClick={() => this.doConnect()}>{strings.connect_wallet}</button>

                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
}

export default Header;