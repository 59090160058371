const rs = {

    confirm: "подтверждаю",
    coming_soon: "иду",
    processing: "настоящее время ведется работа по теме дела были представлены. подождите",
    error: "ошибка",
    success: "успешно",
    close: "закрыть",
    language: "язык",
    language_changed: "language changed",
    install_metamask: "установка metamask",
    please_connect: "Пожалуйста, соедините бумажник",
    loading: "обработка...",
    approve: "ратификация",
    rejected: "отказ",
    running: "бегом марш",

    // заголовок
    package: "пакет",
    arbitrage: "Хедли",
    transaction: "сделка",
    connect: "соединение",
    connected:"подключено",
    connect_wallet: "соединить бумажник",

    // домашняя страница
    take_of_control: "безопасный и удобный",
    your_finance_with: "« увеличение богатства»",
    wall_street_wolves: "Волки на Уолл - стрит",
    a_secure: "безопасная глобальная автоматизированная платформа цифровых финансовых услуг, санкционированная ЕС",
    generate_wallet: "Пророчество робота WSW",
    get_started: "начать использовать",
    earn_upto: "Зарабатывай до 400%",
    interest: "Назад!",
    dont_just_let_your: "не позволяйте вашим USDT и WOLF зашифровать валюту. Используйте WSW язык робот - утилита для получения сверхвысокой прибыли",
    create_account: "служба запуска",
    your_key: "твой ключ",
    crypto_market: "шифрование рынка",
    unlock_eme: "безопасные сделки на бирже, ведущей, лицензированной и регулируемой рынком WSW, с тем чтобы быстро и надежно закрыть новые возможности",
    exp_arena: "Exploring the зашифрованные активы Арена ",
    check_our_blog: "знайте темы, связанные с конкурентной игрой в блоки, DEFI и зашифрованные активы. прочитайте наши инструкции, чтобы начать соревнование по цифровым активам.",
    T1: "4 недели назад",
    Tt1: "Медведи или быки, вы не должны паниковать, wsw приносит вам разные доходы",
    TTT1: "из кума Сити",
    T2: "2 месяца назад",
    Tt2: "децентрализация",
    Ttt2: "organization (DAO) explanation",
    T3: "3 месяца назад",
    Tt3: "Top DeFi зашифрованная валюта 2022",
    T4: "3 个月前 ",
    Tt4: "Top Shift Trends in 2022: The Game and",
    Ttt4: "метавселенная",
    rapid_real: "быстро, в реальном времени",
    execution: "Осуществление",
    in_the_volatile: "в борьбе с отмыванием денег « каждая секунда имеет важное значение в изменчивом зашифрованном валютном обмене. наши высокие технологии реагирования могут сразу же реагировать на многочисленные сделки одновременно».",
    maximize: "最大化 ",
    your_passive_profits: "Ваша пассивная прибыль",
    stacke_your_fund: "с помощью нашей программы перейти к центральной ставки прибыли метиленовый робот Хедли планирует инвестировать ваши средства и заключить сделку с нашей автоматизированной системой Хедли торговли, чтобы насладиться ежегодными ставками прибыли, которые невозможно сравнить где бы то ни было в отрасли",
    putting_your: "Принципы инвестирования",
    security_first: "безопасность основной суммы инвестиций и Фонда поступлений является первым фактором",
    fully_eu: "полностью лицензированный и регулируемый ЕС, Arbismart выполняет самые строгие технические соглашения для обеспечения целостности счетов, защиты конфиденциальности и безопасности всех автоматизированных операций",
    start_now: "немедленно начать",

    //валютный арбитраж
    what_is_arbitrage: "Что такое « Чейз»?",
    arbitrage_involves:"Хедли предполагает покупку активов на одном рынке, а затем продажу активов на другом рынке в целях получения прибыли от разницы в ценах между ними.",
    what_is_carbitrage: "Что такое « Чейз шифр»?",
    take_adv: "шифр утилиты используют временные низкие цены - монеты могут быть использованы в разное время по различным ценам. монеты покупаются на самой низкой бирже, а затем продаются на самой высокой бирже, чтобы получить прибыль",
    take_adv1:"WSW - это полностью контролируемая ЕС автоматизированная система, которая сканирует 35 бирж раз в 24 / 7",
    take_adv2: "безопасно и надежно, наша передовая ноу - хау позволяет вам спокойно инвестировать",
    take_adv3:"платформа дает высокую прибыль отрасли, в зависимости от размера инвестиций до 45% в год",
    why_become_an_arbitrage: "Почему сёлу Беу ян сю арбитраге: « почему он стал утилитами»?",
    investor_with: "investor with",
    s1: "несравненный потенциал прибыли",
    ss1: "Используйте вашу законную валюту и зашифрованные денежные средства, чтобы получить беспрецедентную пассивную прибыль и профицит промышленности",
    s2: "самая передовая технология",
    ss2: "использование передовых технологий, способных быстро обрабатывать большие объемы данных молниеносно, в реальном времени выигрывает от зашифрованных валютных рынков",
    s3: "лицензия и контроль ЕС",
    ss3: "спокойные инвестиции, иметь специальную безопасную и лицензированную платформу, отвечающую самым строгим стандартам контроля",
    s4: "доступ через любое устройство",
    ss4: "Управление портфелем ваших инвестиций дома или вне дома, доступ к вашему счету ArbiSmart через ваш компьютер, планшет или мобильный телефон",
    s5: "эксперты 24 / 7 помощь",
    ss5: "из нашей опытной группы на глобальном рынке шифрования, весь день получить первоклассные услуги и поддержку",
    s6 :"Поддержка легальных и зашифрованных валют",
    ss6: "использовать различные легальные и зашифрованные валюты, используя выгодные возможности шифрования, хеджирования и бумажника",
    
    //поддержать
    contact_us:"Свяжись с нами",
    get_in_touch_1: "свяжитесь с нашей отраслевой экспертной группой и примите личные и профессиональные услуги",
    get_in_touch_1: "с помощью электронной почты и реального времени чат, наша глобальная поддержка круглосуточно предоставляет руководство",
    open_chat: "открыть чат",
    email: "адрес электронной почты",
    via_email: "свяжитесь с нашей командой по электронной почте в любое время support@wallstreetwolves.com ",
    live_chat: "Wall Street волкодав, профессионалы во всем мире, через Интернет чат",
    
    //посылка
    status: "состояние",
    arbitrage_amount: "сумма Хедли",
    arbi_colelcted: "коллекция arbi",
    collect_roi: "коллекция roi",
    end:"конец",
    your_wallet: "Твой кошелек",
    min:"минимум",
    max: "Макс",
    Apy: "apy",
    purchase_now: "покупка",
    node_purchase: "купить буфет ",
    usdt_amount: "usdt number ",
    usd_balance: "USDT сальдо",
    wolf_balance: "Волк равновесие",
    collect:"сбор",
    approve_wolf_notice: "управленческие расходы требуют wolf. вы согласны? ",
    approve_usdt_notice: "волк на Уолл - Стрит будет вычесть из вашего кошелька ээ",
    submit: "Submit",
    enter_usdt_amount: "введите сумму usdt",
    next: "следующий",
    confirm_purchase: "подтверждение покупки?",
    purchase_complete: "покупка успеха ",
    purchase_fail: "покупка не состоялась ",
    view_transaction: "Просмотр служб в менеджере ресурсов",
    pacakge_1_limit: "посылка - А USDT должна быть между 100 и 499",
    pacakge_2_limit:"B пакет USDT должен быть между 500 и 1999",
    pacakge_3_limit:"посылка C USDT должна быть между 2000 и 9999",

    // Нижний колонтитул
    note_1:"этот сайт управляется группой волков Уолл - стрит, которая зарегистрирована в Азии и лицензирована FCIS для предоставления услуг виртуальной валюты",
    arbitrage: "Хедли",
    accounts:"счёт",
    partnership: "партнер",
    support: "Поддержка",
    company:"компания",
    careers: "профессия",
    how_it_work:"как надо работать",
    roadmap:"дорожная карта",
    RSP: "Политика в области рисков",
    Rp: "политика возмещения",
    Kyc: "kyc стратегия",
    Am: "политика борьбы с отмыванием денег",
    Tc: "условия и условия",
    Pp: "Private policy",
    quthorizations: "разрешение",
    blog: "блог",
    faq: "Общие вопросы",

    // утил
    eth_main_net: "Eth. болезнь main",
    bsc_main_net: "bsc главная сеть",
    bsc_test_net: "bsc тест сети",
    unknown_net: "неизвестная сеть",
    ongoing:"продолжается",
    expired: "срок годности",
    collected:"сбор",

    //компания
    line_1:"Первая строка: « Уолл - стрит Вульф является новым поколением цифровых фондов под совместным руководством Питера, Джона, Джулиана Джесси и Энтони, известных партнеров из международной финансовой корпорации.",
    line_2: "Уолл - стрит Вульф имеет целью создание глобального глобального глобального глобального цифрового Фонда. в качестве финансовой платформы для оказания услуг глобальным любителям цифровых валют и предоставления им самых профессиональных и высококачественных цифровых финансовых услуг, цифровой фонд Уолл - стрит волков будет в первую очередь внедрять систему 100% централизованной торговли предсказывать сделки робот - прибыль, а также выпустить экологическую монету для развития всей цифровой финансовой экосистемы.",
    line_3: "в будущем Уолл - стрит Вульф будет заниматься многими операциями, связанными с финансированием цифровых технологий, включая инвестиционный фонд цифровых активов, кредитование цифровых активов, хеджирование цифровых активов, большой фонд данных, Космический фонд недвижимости для цифрового туризма и многие другие операции. Ips.",
    line_4:"через разумное закрытие операций по оказанию финансовых услуг, высокие нормы прибыли от инвестиций будут приносить пользу всем пользователям в мире. для достижения роста богатства. волки Уолл - стрит введут вас в лучший финансовый мир",
   
    // общие вопросы
    q1:"Q1: Что за платформа для волков Уолл - стрит?",
    a1: "A1: Уолл - стрит Вульф является глобальной и международной платформой цифровых финансовых услуг нового поколения. она будет опираться на множество операций, производных от цифрового финансирования, включая Фонд инвестиций в цифровые активы, кредитование цифровых активов, хедж - Фонд цифровых активов, крупные фонды данных и недвижимость для цифрового туризма. Фонд метаверс и многие другие операции",
    q2:"Q2:как волки Уолл - стрит участвовали?",
    a2: "A2: через бинance смарт - цепи Бинг пойти на центральную биржу купить WOLF и USDT платформы волков Уолл - стрит, а также войти на платформу волков Уолл - стрит, чтобы выбрать подходящий пакет услуг, вы можете запустить прогноз робот - Хедли сервис",
    q3:"Q3:Третья тема: « третий вопрос. как волки Уолл Стрит получают прибыль?",
    a3: "A3: Уолл - стрит Вульф предсказывает, что робот - сервис будет в соответствии с выбранным пользователем пакетом услуг для хеджирования, ежедневная выручка будет рассчитываться в реальном масштабе времени в соответствии с соглашением пользователя перейти в центральный кошелек, ставка ежедневной прибыли от 1,2% до 4%",
    q2:"Q4: Волки на Уолл - стрит в безопасности?",
    a4: "A4: Уолл - стрит волков является Глобальной платформой цифровых финансовых услуг, одобренной и действующей финансовыми учреждениями Европы и США. она была создана многими известными фондами и партнерами по инвестициям. Уолл - стрит волков имеет сильную техническую команду, лучший партнер Фонда и большое количество глобальных фондов",
    
    //политика риска
    risk_policy_management:"стратегии управления рисками",
    policy:"Стратегия",
    policy_desc:"Мы привержены систематическому и эффективному управлению потенциальными возможностями и негативными последствиями на основе внедрения передовой практики управления рисками",
    philosophy: "философия",
    philosophy_desc:" WSW принимает на себя интеллектуальный риск и понимает, что риск может иметь как позитивные, так и негативные последствия ",
    objectives: "цель",
    objectives_desc: "Управление рисками помогает нам достичь цели эффективной эксплуатации, защиты наших людей и имущества, принятия разумных решений и соблюдения применимых законов и правил».",
    business_plan: "планирование",
    business_plan_desc:"управление рисками будет в полной мере интегрировано в корпоративные процессы на всех уровнях для обеспечения того, чтобы управление рисками учитывалось в рамках обычной коммерческой деятельности.",
    application: "приложение",
    application_desc: "ежегодно будет разработана официальная стратегия управления рисками, которая будет непосредственно и четко поддерживать корпоративные цели и которая будет осуществляться при постоянном участии на всех уровнях организации на основе плана выделения достаточных ресурсов с поддающимися измерению сроками и целями. образование и профессиональная подготовка ",
    performance:"свойства",
    performance_desc: "наш успех в управлении рисками будет измеряться его воздействием на цели компании, ревизией, ежегодным обзором управления рисками, непрерывным сбором данных о рисках и моделями оценки рисков",
    acceptance:"критерии приемки",
    acceptance_desc: "высокий, экстремальный и / или стратегический риск контролируется действиями старшего руководства и определяется документально подтвержденная стратегия лечения",
    documentation:"Документация",
    documentation_desc: "каждый этап процесса управления рисками имеет надлежащую документацию, в частности, для принятия решений и управления рисками. каждый проект и команда регистрируют риски, в то время как корпоративные риски регистрируются в базе данных о стратегических рисках",
    responsibilities:"ответственность",
    responsibilities_desc_1: "Обязанности § 1. « Управление рисками - это основной бизнес - навык и неотъемлемая часть повседневной деятельности.",
    responsibilities_desc_2: "обязанности ( « руководители и руководители всех уровней несут ответственность за применение в рамках своих обязанностей согласованных политики, руководящих принципов и стратегий управления рисками и за обеспечение того, чтобы управление рисками было полностью интегрировано и учитывалось в рамках обычных процессов на всех уровнях. и включить риск в качестве первого пункта повестки дня всех совещаний ",
    responsibilities_desc_3: "Обязанности § 3. « руководитель департамента отвечает за ежемесячное представление Руководящему комитету по управлению рисками докладов о ходе выполнения планов управления рисками и их устранения, своевременное представление докладов о стратегиях или экстремальных рисках, содействие внедрению системы управления рисками и обеспечение того, чтобы менеджер обладал необходимыми навыками, руководящими указаниями и инструментами».",
    responsibilities_desc_4: "начальник отдела нравов отвечает за разработку, координацию и распространение системы управления рисками. Это включает разработку учебных планов и внедрение систем управления, позволяющих выявлять, отслеживать и сообщать о новых или возникающих рисках. Главный сотрудник по рискам также отвечает за обзор процессов управления рисками, контроль и отчетность по ключевым стратегическим рискам».",
}
export default rs;