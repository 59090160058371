import en from './en';
import zh from './zh';
import rs from './rs';
import kr from './kr';
import vn from './vn';
import jp from './jp';
import th from './th';

import LocalizedStrings from 'react-localization';
let strings = new LocalizedStrings({
    en:en,
    zh:zh,
    th:th,
    jp:jp,
    vn:vn,
    kr:kr,
    rs:rs,
});
if (localStorage.getItem('language')) {
    strings.setLanguage(localStorage.getItem('language'));
}
else{
    localStorage.setItem("language", 'en');
    strings.setLanguage('en');
}

export default strings;