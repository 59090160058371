import React from "react";
import './../App.css';
import strings from "./Language";
class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
        }
    }

    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <figure>
                                <img src="assets/images/logo_01.png" alt="" />
                            </figure>
                            <p>{strings.note_1}</p>
                        </div>
                        <div className="col-md-3 mt-5">
                            <ul>
                                <li><a href="/arbitrage">{strings.arbitrage}</a></li>
                                <li><a href="/package">{strings.accounts}</a></li>
                                <li><a href="/company">{strings.company}</a></li>
                                <li><a href="/risk-policy">{strings.rsp}</a></li>
                                <li><a href="/faq">{strings.faq}</a></li>
                                {
                                    this.state.lang=="zh"?
                                    <li><a href="/WOLF_HOW_TO_WORK_ZH.pdf" target={"_blank"}>{strings.how_to_operate}</a></li>
                                    :
                                    <li><a href="/WOLF_HOW_TO_WORK_EN.pdf" target={"_blank"}>{strings.how_to_operate}</a></li>
                                }
                                
                            </ul>
                        </div>
                        <div class="col-md-3 mt-5">
                            <h4>{strings.partnership}:</h4>
                            <ul class="pt-2">
                                <li><img src="assets/images/ptn_01.png" alt=""/></li>
                                <li><img src="assets/images/ptn_02.png" alt=""/></li>
                                <li><img src="assets/images/ptn_03.png" alt=""/></li>
                                <li><img src="assets/images/ptn_04.png" alt=""/></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;