import React from "react";
import Footer from '../components/Footer';
import Header from "../components/Header";
import strings from "../components/Language";
import './../App.css';
import Web3 from "web3";
import {NODE_URL } from './../config/node';

class RiskPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: strings.getLanguage(),
        }
        this.signupPopup = React.createRef();
    }

    async componentDidMount() {
        if (window.ethereum) {
            const web3 = new Web3(window.ethereum || NODE_URL);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                
            }
        }
    }

    render() {
        return (
            <>
                <Header />
                    <section className="common">
                        <div className="container">
                            <div className="row">
                                <div className="col policy">
                                    <p>{strings.risk_policy_management}</p>
                                    <p>{strings.policy}<br/>{strings.policy_desc}</p>
                                    <p>{strings.philosophy}<br/>{strings.philosophy_desc}</p>
                                    <p>{strings.objectives}<br/>{strings.objectives_desc}
                                    </p>
                                    <p>{strings.business_plan}<br/>{strings.business_plan_desc}</p>
                                    <p>{strings.application}<br/>{strings.application_desc}</p>
                                    <p>{strings.performance}<br/>{strings.performance_desc}</p>
                                    <p>{strings.acceptance}<br/>{strings.acceptance_edsc}</p>
                                    <p>{strings.documentation}<br/>{strings.documentation_desc}</p>
                                    <p>{strings.responsibilities}<br/>
                                        {strings.responsibilities_desc_1}<br/>
                                        {strings.responsibilities_desc_2}<br/>
                                        {strings.responsibilities_desc_3}<br/>
                                        {strings.responsibilities_desc_4}<br/>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                <Footer />
            </>
        );
    }
}
export default RiskPolicy;
