const jp = {
    
    confirm: "確認します。",
    coming_soon: "すぐに:近日公開予定。",
    processing: "「トランザクションを提出してください。」",
    error: "エラー",
    success: "「成功」",
    close:" 閉じます。",
    language: "「言語」",
    language_changed:"言語変更",
    install_metamask: " Metamaskのインストール",
    please_connect: "気楽に接続してください。",
    loading: "処理",
    approve: "承認します。",
    rejected: "拒否された。",
    running: "実行中です。",

    package: "ヘッダー",
    arbitrage: "パッケージ",
    transaction:"「裁定」。",
    connect: "トランザクション",
    connected:"接続",
    connect_wallet:"接続",

    //ホームページ
    take_of_control: "KEEHANNER OFCHERコントロール「安全で便利」",
    your_finance_with:"あなたの富を育てる",
    wall_street_wolves: "オオカバマダラ:ウォールストリートのオオカミ",
    a_secure: "安全なグローバルオートメーション化した、EU認可されたデジタル金融サービスプラットホーム。",
    generate_wallet: "「予言ロボットWSW」",
    get_started: "getCloseを起動します。",
    earn_upto:"最高の400パーセント獲得します。",
    interest:"「帰り！」",
    dont_just_let_your: "あなたのUSDTとWolf CryptoCurrencyを怠惰に座らせないでください。",
    create_account:"アカウントを開始します。",
    your_key:"あなたの鍵",
    crypto_market:"「暗号市場」",
    unlock_eme:"WSWの市場をリードし、安全かつ迅速に信頼性の高い取引機会のロックを解除するには、ライセンスと規制の交換取引。",
    exp_arena:"「クリプト資産アリーナを調査してください」。",
    check_our_blog: "ブロックチェーン、defi、および暗号資産の競争力のあるゲームに関連するトピックについて学ぶ。我々のガイドをお読みくださいデジタル資産競争力の旅を開始します。",
    t1 :"「4週間前」",
    tt1 :"ベアマーケットや強気市場、パニックにする必要はありません、WSWはあなたに異なる所得オプションをもたらします。",
    ttt1:"ベア市場から",
    t2 :"「2ヶ月前」",
    tt2 :"「分散自治」",
    ttt2: "「組織（dao）」は説明した。",
    t3 :"「3ヶ月前」",
    tt3 :"「最優先の2022年暗号通貨」",
    t4 : "3个月前」",
    tt4 : "「2022年のトップ暗号化傾向:ゲームと」",
    ttt4 : " metaverse ",
    rapid_real: "高速リアルタイム",
    execution: "実行",
    in_the_volatile:"揮発性のCryptoCurrency交換において、あらゆる第2のカウント。",
    maximize:"最大化」",
    your_passive_profits:"あなたの受動的利益",
    stacke_your_fund:"私たちの分散利回りオラクルボットのアービトラージプログラムを介してお金を投資し、我々の自動裁定取引システムとの貿易は、業界のどこにも比類のないリターンの年率をお楽しみください。",
    putting_your:"あなたの「投資原則」",
    security_first: "投資元本と所得ファンドのセキュリティは、最初の要因です。",
    fully_eu:"完全なEUは認可されて、規制されます、Arbismartはアカウント完全性を確実にするために最も厳しい技術的なプロトコルを実行します。そして、すべての自動化された取引のプライバシーとセキュリティを保護します",
    start_now:"今すぐスタート", 

    //套汇
    what_is_arbitrage: "什么是套利？",
    arbitrage_involves:"套利涉及在一个市场上购买资产,然后在另一个市场出售资产,以从两者之间的价差中获利。套利被广泛认为提供了一个有吸引力的投资机会,因为它倾向于提供健康的回报,同时使投资者面临最小风险。",
    what_is_carbitrage:"什么是加密套利？",
    take_adv:"加密套利利用了暂时的价格低效-硬币以不同价格同时可用的短暂间隔。硬币在价格最低的交易所购买,然后在价格最高的交易所出售,以产生利润。",
    take_adv1:"WSW是一个完全由欧盟监管的自动化系统,它一次24/7扫描35个交易所。",
    take_adv2:"安全可靠,我们先进的专有技术使您能够安心投资",
    take_adv3:"该平台可产生行业高回报,根据投资规模每年高达45%",
    why_Be_an_arbitrage:"为什么成为套利",
    investor_with:"investor with",
    s1:"无与伦比的利润潜力",
    ss1:"利用您的法定货币和加密货币资本,享受获得无与伦比的被动利润和行业领先利率的机会",
    s2:"最先进的技术",
    ss2:"利用能够以闪电速度处理大量数据的先进技术,实时受益于加密货币市场",
    s3:"欧盟许可和监管",
    ss3:"安心投资,拥有一个特别安全、许可的平台,符合最严格的监管标准",
    s4:"通过任何设备访问",
    ss4:"在家中或外出时管理您的投资组合,随时通过您的电脑、平板电脑或手机访问您的ArbiSmart帐户",
    s5:"专家24/7协助",
    ss5:"从我们经验丰富的全球加密市场专业团队中,全天候获得一流的服务和支持",
    s6:"支持法定货币和加密货币",
    ss6:"利用多种法定货币和加密货币,利用有利可图的加密交易、套利和钱包机会",

    //支持
    contact_us: "联系我们",
    get_in_touch_1:"与我们的行业专家专家团队取得联系,并接受个人、专业的服务",
    get_in_touch_1:"通过电子邮件和实时聊天,我们的全球支持人员每天24小时提供指导。",
    open_chat:"打开聊天",
    email:"电子邮件",
    via_email: "通过电子邮件联系我们的团队,在任何时候support@wallstreetwolves.com",
    live_chat:"华尔街狼群专家,全球专业人士,全天候,通过实时聊天",

    //包裹
    status:"状态",
    arbitrage_amount: "套利金额",
    arbi_colelcted:"arbi收藏品",
    collect_roi:"收集roi",
    end: "结束",
    your_wallet: "你的钱包",
    min:"最小",
    max: "最大值",
    apy:"apy",
    purchase_now:"购买",
    node_purchase:"套餐购买",
    usdt_amount:"usdt金额",
    usd_balance:"USDT余额",
    wolf_balance: "狼平衡",
    collect: "收集",
    approve_wolf_notice:"管理费需要wolf的数量。您同意吗？",
    approve_usdt_notice:"华尔街之狼将从您的钱包中扣除｛0｝美元。您同意吗？",
    submit:"提交",
    enter_usdt_amount:"请输入usdt金额",
    next:"下一个",
    confirm_purchase: "确认购买？",
    purchase_complete:"购买成功",
    purchase_fail:"购买失败",
    view_transaction:"在资源管理器中查看事务",
    pacakge_1_limit:"包裹-A USDT必须介于100和499之间",
    pacakge_2_limit:"B包USDT必须在500和1999之间",
    pacakge_3_limit:"包裹C USDT必须在2000和9999之间",

    // 页脚
    note_1:"本网站由华尔街狼队运营,该公司在亚洲注册,并经FCIS授权提供虚拟货币服务",
    arbitrage: "套利",
    accounts:"账户",
    partnership: "合作伙伴",
    support: "支持",
    company: "公司",
    careers: "职业",
    how_it_work:"如何工作",
    roadmap:"路线图",
    rsp:"风险政策",
    rp:"退款政策",
    kyc:"kyc策略",
    am:"反洗钱政策",
    tc:"条款和条件",
    pp:"隐私政策",
    quthorizations: "授权",
    博客:"博客",
    faq: "常见问题",

    //Util
    eth_main_net:"eth主网",
    bsc_main_net:"bsc主网",
    bsc_test_net:"bsc测试网",
    unknown_net:"未知网络",
    ongoing:"正在进行",
    expired: "过期",
    collected:"收集",

    //公司
    line_1:"华尔街狼是由国际金融基金公司的著名合伙人彼得、约翰、朱利安·杰西和安东尼共同领导的新一代全数字基金。一个新的数字帝国即将崛起。",
    line_2:"华尔街狼的目的是创建一个全球全属性数字基金。作为一个金融服务平台,为全球数字货币爱好者提供服务,并为他们提供最专业和高质量的数字金融服务,华尔街狼数字基金将首先推出一个100%去中心化交易预测交易机器人利润服务系统,并发行生态币用于整个数字金融生态系统的开发。",
    line_3:"在未来,华尔街狼将围绕数字金融衍生许多业务,包括数字资产投资基金、数字资产借贷、数字资产对冲基金、大数据基金池、数字资产旅游房地产元宇宙基金池和许多其他业务。华尔街狼拥有最强大的技术团队、最佳基金合作伙伴和庞大的全球关系网络ips。",
    line_4:"通过合理的金融服务业务闭环,高投资回报率必将让世界上的每一位用户都受益。实现财富增长。华尔街狼将带您进入一个更美好的金融世界。",

    //常见问题
    q1:"q1.华尔街狼是什么平台？",
    a1:"a1:华尔街狼是一个全球和国际新一代数字金融服务平台。它将围绕数字金融衍生许多业务,包括数字资产投资基金、数字资产借贷、数字资产对冲基金、大数据基金池和数字资产旅游房地产。Metaverse基金池和许多其他业务。",
    q2:"q2.华尔街狼是如何参与的？",
    a2:"a2:通过Binance智能链下的Bing去中心化交易所购买华尔街狼平台代币WOLF和USDT,并进入华尔街狼的平台选择适合您的服务包,您可以启动预测机器人套利服务。",
    q3:"q3:第三题。华尔街狼如何获利？",
    a3:"a3:华尔街狼预测机器人套利服务将根据用户选择的服务包进行套利,每日套利收入将根据用户去中心化钱包中的协议实时结算,每日回报率从1.2%到4%。",
    q4:"q4.华尔街狼安全吗？",
    a4:"a4:华尔街狼是一个由欧美金融机构批准和运营的全球数字金融服务平台。它由许多知名基金投资合作伙伴共同创建。华尔街狼拥有最强大的技术团队、最好的基金合作伙伴和大量的全球基金。关系网络安全可靠。",

    //风险政策
    risk_policy_management:"风险管理策略",
    policy: "策略",
    policy_desc:"我们致力于通过实现风险管理的最佳实践,对潜在机会和不利影响进行系统和全面的有效管理。",
    philosophy: "哲学",
    philosophy_desc:"WSW拥抱智能风险承担,并认识到风险可以产生积极和消极的后果。",
    objectives: "目标",
    objectives_desc: "风险管理帮助我们实现目标,有效运营,保护我们的人员和资产,做出明智的决策,并遵守适用的法律法规。",
    business_plan:"业务规划",
    business_plan_desc:"风险管理将与各级公司流程充分整合,以确保在正常业务活动过程中考虑到风险管理。",
    application:"应用程序",
    application_desc:"每年将制定正式的风险管理战略,该战略将直接和明确地支持公司目标。该战略将在组织各级的持续参与下,通过具有可测量时间表和目标的充足资源计划实施。我们的系统符合ISO31000:2009风险管理标准,并得到持续的教育计划的支持教育和培训。",
    performance:"性能",
    performance_desc:"我们风险管理的成功将通过其对公司目标的影响、审计、年度风险管理审查、持续收集风险数据和评估风险模型来衡量。",
    acceptance:"验收标准",
    acceptance_desc:"高风险、极端风险和/或战略风险通过高级管理层的行动进行控制,并指定有文件记录的治疗策略。中等风险由特定的管理责任负责,而低风险则通过常规程序进行管理。",
    documentation:"文档",
    documentation_desc:"风险管理过程的每个阶段都有适当的文档记录,特别是决策和风险处理。各个项目和团队保持风险登记,而企业风险记录在战略风险数据库中。",
    responsibilities: "责任",
    responsibilities_desc_1:"风险管理是一项核心业务技能,也是日常活动的一个组成部分。作为个人,我们都在管理风险中发挥作用,各级员工都有责任理解和实施其工作场所的风险管理系统。",
    responsibilities_desc_2:"各级管理人员和领导负责在其职责范围内应用商定的风险管理政策、指导方针和战略,并确保风险管理与各级正常活动过程充分整合和考虑。可见的承诺要求积极参与风险管理流程、有效的资源分配、有效的风险管理和风险管理并将风险作为所有会议的第一个议程项目。",
    responsibilities_desc_3:"部门经理负责每月向风险管理指导委员会报告风险和处理计划的进展,及时报告战略或极端风险,推动风险管理框架的实施,并确保经理具备必要的技能、指导和工具。",
    responsibilities_desc_4:"首席风险官（CRO）负责制定、协调和发布风险管理框架。这包括制定培训计划和实施能够识别、监控和报告记录的、新的或正在出现的风险的管理系统。首席风险官还负责审查风险管理流程,监测和报告关键战略风险。",
}
export default jp;